import { Img, Stack, useDisclosure, VStack } from '@chakra-ui/react';
import React from 'react';
import { useContext } from 'react';
import Balcao from '../../../assets/images/balcao2.svg';
import MenuIcon from '../../../assets/images/icons/menu-icon.svg';
import { AccountContext } from '../../AccountContext';
import MainDrawer from '../../Menu/MainDrawer';

function MainHeader() {
  const { menuDisclosure } = useContext(AccountContext);
  const { user, setUser } = useContext(AccountContext);
  return (
    <>
      <MainDrawer disclosure={menuDisclosure} />
      <VStack w="full" shadow="md" backgroundColor="#5AB2AD" borderBottomLeftRadius="100em 55em" borderBottomRightRadius="50em 24em">
        <Stack w="85%" pt="2.5em">
          <Img
            src={MenuIcon}
            alt="menu"
            w="2em"
            mt="2em"
            onClick={() => {
              if (user.perfil_id === 4) {
                setUser((prevState) => ({ ...prevState, menuMedico: true }));
              }

              menuDisclosure.onOpen();
            }}
          />
        </Stack>
        <Img alignSelf="start" src={Balcao} alt="recepcao deovita" w="100%" borderBottomLeftRadius="100em 50em" borderBottomRightRadius="50em 22em" />
      </VStack>
    </>
  );
}

export default MainHeader;
