import {
  Button,
  useToast,
  ButtonGroup,
  Heading,
  Text,
  VStack,
  chakra,
  Img,
  Input,
  Center,
  Grid,
  GridItem,
  Switch,
  FormLabel,
  Flex,
  HStack,
  Checkbox,
  Spacer,
} from '@chakra-ui/react';
import { React, useContext, useRef, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { AccountContext } from '../../AccountContext';
import { Form, Formik, validateYupSchema, formik } from 'formik';
import TextField from '../../Utils/Inputs/TextField';
import NoUserHeader from '../../Utils/Headers/NoUserHeader';
import * as Yup from 'yup';
import { parse } from 'date-fns';
import SelectField from '../../Utils/Inputs/SelectField';
import validaCPF from '../../Utils/Functions/validaCPF';
import { FaCircleInfo } from 'react-icons/fa6';

function GestordaClinica() {
  const { setUser, user, setPage, menuDisclosure } = useContext(AccountContext);
  const [error, setError] = useState(null);
  const navigate = useNavigate();
  const toast = useToast();
  const { state } = useLocation();
  const onlyNumbers = (str) => str.replace(/[^0-9]/g, '');
  const [sexo, setSexo] = useState('M');
  const [loading, setLoading] = useState(false);
  const checkboxRef = useRef(null);
  const [checkboxBox, setCheckboxChecked] = useState(true);
  setPage('Cadastro');
  function showToast() {
    toast({
      title: '',
      position: 'top',
      description: 'Sistema em Manutenção',
      status: 'error',
      duration: 2000,
      isClosable: true,
      containerStyle: {
        paddingTop: '50px',
      },
    });
  }

  function enviarWpp(nome, cpf, wpp) {
    fetch(`${process.env.REACT_APP_BASE_URL_API_SERVER}/whatsapp/cadastro_whatsapp/${nome}/${cpf}/${user.empresa}/${user.cnpj}/${wpp}`, {
      method: 'POST',
      credentials: 'include',
      headers: {
        'Content-Type': 'application/json',
      },
    });
  }

  function searchCRM(crm, cpf) {
    return new Promise((resolve, reject) => {
      fetch(`${process.env.REACT_APP_BASE_URL_API_SERVER}/clients/search_crm`, {
        method: 'POST',
        credentials: 'include',
        headers: { 'content-type': 'application/json' },
        body: JSON.stringify({
          crm: crm,
          empresa_id: user.empresa_id,
          cpf: cpf,
        }),
      })
        .then(async (response) => {
          if (!response.ok) {
            throw new Error(`Erro na solicitação. Código: ${response.status}`);
          }

          const data = await response.json();
          console.log(data);

          // Supondo que o servidor envie uma mensagem indicando a existência ou não do CRM
          if (
            data.message === 'existe' ||
            data.message === 'nao_existe' ||
            data.message === 'cpf & crm já existe' ||
            data.message === 'crm já existe' ||
            data.message === 'cpf já existe'
          ) {
            resolve(data.message);
          } else {
            reject(`Resposta inesperada do servidor: ${data.message}`);
          }
        })
        .catch((error) => {
          reject(error);
        });
    });
  }
  function callToast(title, description, status) {
    toast({
      title: title,
      description: description,
      status: status,
      duration: 4000,
      isClosable: true,
      position: 'bottom',
      colorScheme: 'red',
      containerStyle: {
        paddingTop: '50px',
      },
    });
  }

  async function validaUf(uf_clinica) {
    setLoading(true);
    try {
      const response = await fetch(`${process.env.REACT_APP_BASE_URL_API_SERVER}/clients/valida_uf/${uf_clinica}`, {
        method: 'POST',
        credentials: 'include',
        headers: {
          'Content-Type': 'application/json',
        },
      });

      const resposta = await response.json();

      if (resposta.message === 'existe') {
        setLoading(false);
        return true;
      }
      setLoading(false);
      return false;
    } catch (error) {
      console.error('Error during validaUf:', error);
      return false;
    }
  }

  const handleCheckboxChange = (event) => {
    setCheckboxChecked(event.target.checked);
  };

  return (
    <>
      <NoUserHeader />
      <VStack>
        {' '}
        <Formik
          initialValues={{ cpf: '', nome: '', telefone: '', whatsapp: '', nascimento: '', conselho: '' }}
          validateOnChange={false}
          validateOnBlur={false}
          validationSchema={Yup.object({
            nome: Yup.string()
              .required('Nome é obrigatório!')
              .matches(/^[A-Za-z]+\s[A-Za-z ]+$/m, 'Escreva o nome e sobrenome pelo menos e sem acentos ou caracteres especiais')
              .max(50, 'O nome não pode ter mais de 50 caracteres'),
            whatsapp: Yup.string()
              .required('Whatsapp é obrigatório')
              .trim()
              .matches(/^\(?[1-9]{2}\)? ?(?:[2-8]|9[1-9])[0-9]{3}\-?[0-9]{4}$/, 'Whatsapp Inválido'),
            nascimento: Yup.date()
              .transform((value, originalValue) => parse(originalValue, 'dd/MM/yyyy', new Date()))
              .required('Data de nascimento é obrigatória!')
              .typeError('Data nascimento, digite uma data no formato DD/MM/AAAA')
              .test('minDate', 'A data de nascimento deve ser a partir de 1900', function (value) {
                const minDate = new Date(1900, 0, 1);
                return value >= minDate;
              })
              .test('minAge', `O médico deve ter pelo menos 18 anos`, function (value) {
                const currentDate = new Date();
                const eighteenYearsAgo = new Date(currentDate.getFullYear() - 18, currentDate.getMonth(), currentDate.getDate());
                return value <= eighteenYearsAgo;
              }),
            sexo: Yup.number().required('Campo obrigatório'),
            cpf: Yup.string()
              .required('Cpf Obrigatório!')
              .trim()
              .test('valida_cpf', 'Cpf inválido', (cpf) => validaCPF(cpf)),
            conselho: Yup.string()
              .required('Campo obrigatório')
              .matches(/^[a-zA-Z0-9]+$/, 'O conselho não pode conter espaços ou caracteres especiais')
              .max(50, 'O conselho não pode conter mais de 50 caracteres'),
            uf: Yup.string()
              .required('Campo obrigatório')
              .matches(/^[^\d]+$/, 'Escreva a UF sem acentos, números ou caracteres especiais')
              .max(2, 'A UF não pode conter mais de 2 caracteres')
              .test('validate-uf', 'UF inválida', async function (value) {
                if (value) {
                  const isValid = await validaUf(value);
                  return isValid;
                }
                return true;
              }),
            email: Yup.string().trim().email('Email inválido'),
          })}
          onSubmit={(values, actions) => {
            setLoading(true);
            const vals = { ...values };

            searchCRM(vals.conselho, onlyNumbers(vals.cpf)).then((resposta) => {
              if (resposta === 'cpf & crm já existe') {
                setLoading(false);
                callToast('Importante', 'CRM e CPF já existe em nossa base com um operador ativo ', 'error');
                return;
              } else if (resposta === 'crm já existe') {
                setLoading(false);
                callToast('Importante', 'Este CRM já existe em nossa base com um operador ativo ', 'error');
                return;
              } else if (resposta === 'cpf já existe') {
                setLoading(false);
                callToast('Importante', 'Este CPF já existe em nossa base com um operador ativo ', 'error');
                return;
              } else {
                fetch(`${process.env.REACT_APP_BASE_URL_API_SERVER}/clients/registroMedico/${user.empresa_id}`, {
                  method: 'POST',
                  credentials: 'include',
                  headers: { 'content-type': 'application/json' },
                  body: JSON.stringify({
                    nome: vals.nome,
                    nascimento: new Date(vals.nascimento.split('/').reverse().join('-')).toLocaleDateString('en-CA'),
                    conselho: vals.conselho,
                    cpf: vals.cpf,
                    sexo: vals.sexo === 0 ? 'M' : 'F',
                    uf: vals.uf,
                    whatsapp: vals.whatsapp,
                    email: vals.email,
                    mensagem: checkboxBox,
                  }),
                })
                  .catch((err) => {
                    return err;
                  })
                  .then((response) => {
                    console.log(response);
                    if (!response || !response.ok || response.status >= 400) {
                    }
                    return response.json();
                  })
                  .then((result) => {
                    if (!result) return;
                    setLoading(false);
                    if (result.message !== 'Algo de errado ocorreu') {
                      setLoading(false);
                      enviarWpp(vals.nome, vals.cpf, vals.whatsapp);
                      setUser((prevState) => ({ ...prevState, qntdeMedico: user.qntdeMedico ? user.qntdeMedico + 1 : 1 }));
                      menuDisclosure.onClose();
                      navigate('/alerts/sucesso/default');
                    }
                  });
              }
            });
          }}
        >
          <VStack as={Form} w="100%">
            <VStack w="90%" textAlign={'center'}>
              <Text fontSize="1em" lineHeight="1em" fontWeight={'bold'} textAlign={'center'} color="#5AB2AD" noOfLines={2} maxWidth="100%">
                Agora cadastre os médicos da clínica
              </Text>
              <TextField
                label="Nome*"
                name="nome"
                placeholder="Digite o nome do médico"
                textAlign="left"
                fontSize="0.9em"
                color="#939598"
                backgroundColor="#EFEFEF"
                maskChar={null}
                type="text"
                h="2.8em"
                fontWeight="bold"
                borderRadius="-0.5em"
                autoComplete="off"
                maxLength={50}
              />
              <TextField
                label="E-mail"
                name="email"
                placeholder="Digite o e-mail do médico"
                textAlign="left"
                fontSize="0.9em"
                color="#939598"
                backgroundColor="#EFEFEF"
                maskChar={null}
                type="text"
                h="2.8em"
                fontWeight="bold"
                borderRadius="-0.5em"
                autoComplete="off"
                maxLength={80}
              />
              <HStack w="100%">
                <TextField
                  name="nascimento"
                  label="Data de nascimento*"
                  placeholder="Data de nascimento*"
                  textAlign="left"
                  fontSize="0.9em"
                  color="#939598"
                  backgroundColor="#EFEFEF"
                  mask="99/99/9999"
                  maskChar={null}
                  type="tel"
                  h="2.8em"
                  fontWeight="bold"
                  borderRadius="-0.5em"
                  autoComplete="off"
                  w="15em"
                />

                <SelectField
                  name="sexo"
                  placeholder="Sexo"
                  borderRadius="-0.5em"
                  w="100%"
                  mt="1em"
                  h="2.5em"
                  ml="1em"
                  fontSize="1em"
                  backgroundColor="#EFEFEF"
                  color="gray"
                  variant="outline"
                  textAlign="left"
                  fontWeight="normal"
                  onChange={(e) => setSexo(e)}
                  options={['Masculino', 'Feminino']}
                  autoComplete="off"
                />
              </HStack>
              <HStack flexDirection={'row'}>
                <TextField
                  name="cpf"
                  label="CPF*"
                  placeholder="CPF*"
                  mask="999.999.999-99"
                  textAlign="left"
                  fontSize="0.9em"
                  color="#939598"
                  backgroundColor="#EFEFEF"
                  maskChar={null}
                  type="tel"
                  h="2.8em"
                  fontWeight="bold"
                  borderRadius="-0.5em"
                  autoComplete="off"
                />

                <TextField
                  name="conselho"
                  label="N° CRM*"
                  textAlign="left"
                  fontSize="0.9em"
                  placeholder="N° CRM"
                  color="#939598"
                  backgroundColor="#EFEFEF"
                  maskChar={null}
                  type="tel"
                  h="2.8em"
                  fontWeight="bold"
                  borderRadius="-0.5em"
                  autoComplete="off"
                  maxLength={50}
                />
              </HStack>
              <HStack w="100%">
                <TextField
                  name="whatsapp"
                  label="Whatsapp*"
                  placeholder="whatsapp"
                  textAlign="left"
                  fontSize="0.9em"
                  color="#939598"
                  backgroundColor="#EFEFEF"
                  maskChar={null}
                  mask="(99)99999-9999"
                  type="tel"
                  h="2.8em"
                  fontWeight="bold"
                  borderRadius="-0.5em"
                  autoComplete="off"
                />
                <TextField
                  name="uf"
                  label="UF*"
                  placeholder="UF"
                  textAlign="left"
                  fontSize="0.9em"
                  color="#939598"
                  backgroundColor="#EFEFEF"
                  maskChar={null}
                  type="text"
                  h="2.8em"
                  fontWeight="bold"
                  borderRadius="-0.5em"
                  autoComplete="off"
                  maxLength={2}
                  required
                />
              </HStack>
              <Spacer mt={'2em'} />
              <HStack w="100%">
                <FaCircleInfo
                  color="teal"
                  onClick={() => {
                    callToast(
                      'Mensagens via Whatsapp',
                      'Ao marcar essa opção, o médico receberá mensagens via whatsapp informando que um paciente que recebeu uma solicitação de atendimento, aceitou a solicitação e pagou pela mesma.',
                    );
                  }}
                />{' '}
                <Checkbox
                  color="Black"
                  ref={checkboxRef}
                  isChecked={checkboxBox}
                  fontSize="16px"
                  alignSelf="flex-start"
                  onChange={(e) => {
                    handleCheckboxChange(e);
                  }}
                >
                  <Text>Receber mensagem via whatsapp?</Text>
                </Checkbox>
              </HStack>
              <Button
                backgroundColor="#95F3EE"
                color="#5AB2AD"
                h="3em"
                type="submit"
                borderRadius="2em"
                w={{ md: '20em', sm: '15em' }}
                mt={{ md: '30em', sm: '20em' }}
                ml={{ md: '2.5em', sm: '4em' }}
                // isLoading={loading}
              >
                Salvar
              </Button>
            </VStack>
          </VStack>
        </Formik>
      </VStack>
    </>
  );
}

export default GestordaClinica;
