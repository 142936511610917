import {
  Button,
  useToast,
  ButtonGroup,
  Heading,
  Text,
  VStack,
  chakra,
  Img,
  Input,
  Center,
  Grid,
  GridItem,
  Switch,
  FormLabel,
  Flex,
  HStack,
  InputRightElement,
  InputGroup,
  useDisclosure,
} from '@chakra-ui/react';
import Voltar from '../../assets/images/voltar.svg';
import { ViewIcon, ViewOffIcon, WarningIcon } from '@chakra-ui/icons';
import { React, useContext, useState } from 'react';
import { AccountContext } from '../AccountContext';
import { useLocation, useNavigate } from 'react-router-dom';
import { Form, Formik } from 'formik';
import TextField from '../Utils/Inputs/TextField';
import Medicos from '../../assets/images/medicos.png';
import NoUserHeader from '../Utils/Headers/NoUserHeader';
import * as Yup from 'yup';
import { parse } from 'date-fns';
import validaCPF from '../Utils/Functions/validaCPF';
import SelectField from '../Utils/Inputs/SelectField';
import ConfirmacaoWhatsapp from '../Utils/Modal/Cadastro/ConfirmacaoWhatsapp';
import ConselhoMedico from '../Utils/Modal/Dashboard/ConselhoMedico';
function GestordaClinica() {
  const { setUser, setPage } = useContext(AccountContext);
  const navigate = useNavigate();
  const toast = useToast();
  const { state } = useLocation();
  const onlyNumbers = (str) => str?.replace(/[^0-9]/g, '');
  const [sexo, setSexo] = useState('M');
  const [error, setError] = useState(null);
  const [show, setShow] = useState(false);
  const [seguir, setSeguir] = useState('');
  const [modal, setModal] = useState(true);
  const confirmarWpp = useDisclosure();
  const [userData, setUserData] = useState(null);
  const conselhoMedicoDisclosure = useDisclosure();
  const [loading, setLoading] = useState(false);

  setPage('Cadastro');
  function showToast() {
    toast({
      title: '',
      position: 'top',
      description: 'Sistema em Manutenção',
      status: 'error',
      duration: 2000,
      isClosable: true,
      containerStyle: {
        paddingTop: '50px',
      },
    });
  }

  const handleClick = () => setShow(!show);
  const mesmoDaClinica = (value, setFieldValue) => {
    setFieldValue('whatsapp_gestor', state.whatsapp_clinica);
    setModal(false);
  };

  const validarWhatsapp = (value, setFieldValue) => {
    setFieldValue('whatsapp_gestor', '');
    setModal(true);
  };

  function enviarWpp(wpp, nome, documento, medico) {
    fetch(`${process.env.REACT_APP_BASE_URL_API_SERVER}/whatsapp/confirma_wpp_empresa/${wpp}/${nome}/${documento}/${medico}`, {
      method: 'POST',
      credentials: 'include',
      headers: {
        'Content-Type': 'application/json',
      },
    });
  }

  function callToast(title, description, status) {
    toast({
      title: title,
      description: description,
      status: status,
      duration: 4000,
      isClosable: true,
      position: 'top',
      colorScheme: 'red',
      containerStyle: {
        paddingTop: '50px',
      },
    })
  }

  function searchCRM(crm) {
    setLoading(true)
    return new Promise((resolve, reject) => {
      fetch(`${process.env.REACT_APP_BASE_URL_API_SERVER}/clients/search_crm_gestor`, {
        method: 'POST',
        credentials: 'include',
        headers: { 'content-type': 'application/json' },
        body: JSON.stringify({
          crm: crm,
          cnpj: state.documento,
        }),
      })
        .then(async (response) => {
          if (!response.ok) {
            throw new Error(`Erro na solicitação. Código: ${response.status}`);
          }

          const data = await response.json();

          // Supondo que o servidor envie uma mensagem indicando a existência ou não do CRM
          if (data.message === 'existe' || data.message === 'nao_existe') {
            setLoading(false)
            resolve(data.message);
          } else {
            setLoading(false)
            reject(`Resposta inesperada do servidor: ${data.message}`);
          }
        })
        .catch((error) => {
          reject(error);
        });
    });
  }

  function nextPage(conselho, vals, medico) {
    const wpp = vals.whatsapp_gestor;
    const nome = vals.nome_gestor;

    setUserData({
      ...state,
      nome: nome,
      medico: false,
      whatsapp: onlyNumbers(wpp),
      nome_gestor: vals.nome_gestor,
      nascimento_gestor: vals.nascimento_gestor.replace(/\//g, '-'),
      sexo_gestor: vals.sexo_gestor === 0 ? 'M' : 'F',
      cpf_gestor: onlyNumbers(state.cpf_gestor),
      crm_gestor: conselho && conselho !== null && conselho !== undefined && conselho !== '' ? conselho : undefined,
      uf_gestor: vals.uf_gestor,
      whatsapp_gestor: onlyNumbers(vals.whatsapp_gestor),
      senha_gestor: vals.senha_gestor,
      email: vals.email,
    });

    if (modal) {
      confirmarWpp.onOpen();

      setLoading(false)
      enviarWpp(onlyNumbers(wpp), nome, onlyNumbers(state.documento), medico);
    } else {
      if (conselho === null || conselho === undefined || conselho === '') {
        setLoading(false)
        conselhoMedicoDisclosure.onOpen();
      } else {
        setLoading(false)
        navigate('/repassefinanceiro', {
          state: {
            ...state,
            medico: conselho && conselho !== null && conselho !== undefined && conselho !== '' ? true : false,
            nome_gestor: vals.nome_gestor,
            nascimento_gestor: vals.nascimento_gestor.replace(/\//g, '-'),
            sexo_gestor: vals.sexo_gestor === 0 ? 'M' : 'F',
            cpf_gestor: onlyNumbers(state.cpf_gestor),
            crm_gestor: conselho && conselho !== null && conselho !== undefined && conselho !== '' ? conselho : undefined,
            uf_gestor: vals.uf_gestor,
            whatsapp_gestor: onlyNumbers(vals.whatsapp_gestor),
            senha_gestor: vals.senha_gestor,
            email: vals.email,
          },
        });
      }
    }
  }

  async function validaUf(uf_clinica) {
    setLoading(true)
    try {
      const response = await fetch(`${process.env.REACT_APP_BASE_URL_API_SERVER}/clients/valida_uf/${uf_clinica}`, {
        method: 'POST',
        credentials: 'include',
        headers: {
          'Content-Type': 'application/json',
        },
      });

      const resposta = await response.json();

      if (resposta.message === 'existe') {
        return true;

      }
      setLoading(false)
      return false;
    } catch (error) {
      console.error('Error during validaUf:', error);
      return false;
    }
  }

  return (
    <>
      <NoUserHeader />
      {userData && (<ConselhoMedico disclosure={conselhoMedicoDisclosure} paciente_data={userData} />)}
      <ConfirmacaoWhatsapp disclosure={confirmarWpp} paciente_data={userData} type="cpf" navig_to="/repassefinanceiro" />
      <VStack>
        {' '}
        <Formik
          initialValues={{ nome_gestor: '', nascimento_gestor: '', whatsapp_gestor: '', cpf_gestor: '', uf_gestor: '', senha_gestor: '' }}
          validateOnChange={false}
          validateOnBlur={false}
          validationSchema={Yup.object({
            nome_gestor: Yup.string()
              .required('Nome é obrigatório')
              .matches(/^[A-Za-zÀ-ÖØ-öø-ÿ\s]+$/, 'Nome não deve conter números, aspas, pontos ou vírgulas')
              .max(50, 'O nome não pode ter mais de 50 caracteres'),
            nascimento_gestor: Yup.date()
              .transform((value, originalValue) => parse(originalValue, 'dd/MM/yyyy', new Date()))
              .required('Data de nascimento é obrigatória!')
              .typeError('Data nascimento, digite uma data no formato DD/MM/AAAA')
              .test('minDate', 'A data de nascimento deve ser a partir de 1900', function (value) {
                const minDate = new Date(1900, 0, 1);
                return value >= minDate;
              }).test('minAge', `O gestor deve ter pelo menos 18 anos`, function (value) {
                const currentDate = new Date();
                const eighteenYearsAgo = new Date(currentDate.getFullYear() - 18, currentDate.getMonth(), currentDate.getDate());
                return value <= eighteenYearsAgo;
              }),

            whatsapp_gestor: Yup.string()
              .required('Whatsapp é obrigatório')
              .trim()
              .matches(/^\(?[1-9]{2}\)? ?(?:[2-8]|9[1-9])[0-9]{3}\-?[0-9]{4}$/, 'Whatsapp Inválido'),
            uf_gestor: Yup.string()
              .required('Campo obrigatório')
              .matches(/^[^\d]+$/, 'Escreva a UF sem acentos, números ou caracteres especiais')
              .max(2, 'A UF não pode conter mais de 2 caracteres')
              .test('validate-uf', 'UF inválida', async function (value) {
                if (value) {
                  const isValid = await validaUf(value)
                  return isValid;
                }
                return true;
              }),
            senha_gestor: Yup.string()
              .required('Senha é obrigatória!')
              .min(6, 'A senha deve ter pelo menos 6 caracteres'),

            email: Yup.string().trim().email('Email inválido'),

            senha_confirmar: Yup.string()
              .required('Confirmação da senha é obrigatória')
              .min(6, 'A senha deve ter pelo menos 6 caracteres')
              .test('pass-match', 'Senhas precisam ser iguais', function (value) {
                return this.parent.senha_gestor === value;
              }),
            sexo_gestor: Yup.number().required('Campo obrigatório'),
          })}
          onSubmit={(values, actions) => {
            setLoading(true)
            const vals = { ...values };
            const medico = 'false';
            const conselho = vals.crm_gestor;

            if (conselho) {
              searchCRM(conselho)
                .then((resposta) => {
                  if (resposta === 'existe') {
                    callToast('Importante', 'Este CRM já existe em nossa base com um operador ativo ', 'error');
                    return
                  } else {
                    nextPage(conselho, vals, medico)
                  }
                })
                .catch((erro) => {
                  setLoading(false)
                  console.error("Erro na pesquisa CRM:", erro);
                });
            } else {
              nextPage(conselho, vals, medico)
            }

          }}
        >
          {(props) => {
            const { setFieldValue } = props;
            return (
              <VStack as={Form} w="100%">
                <VStack w="90%" textAlign={'center'}>
                  <Text fontSize="1em" lineHeight="1em" fontWeight={'bold'} textAlign={'left'} color="#5AB2AD" noOfLines={3} maxWidth="90%">
                    Agora cadastre os dados do gestor da clínica
                  </Text>
                  <TextField
                    label="Nome*"
                    name="nome_gestor"
                    placeholder=""
                    textAlign="left"
                    fontSize="0.7em"
                    color="#939598"
                    backgroundColor="#EFEFEF"
                    maskChar={null}
                    type="text"
                    h="3.8em"
                    fontWeight="bold"
                    borderRadius="-0.5em"
                    autoComplete="off"
                    maxLength={50}
                  />

                  <TextField
                    type="email"
                    name="email"
                    label="Email:"
                    placeholder=""
                    textAlign="left"
                    fontSize="0.7em"
                    color="#939598"
                    backgroundColor="#EFEFEF"
                    maskChar={null}
                    h="3.8em"
                    fontWeight="bold"
                    borderRadius="-0.5em"
                    autoComplete="off"
                    maxLength={80}
                  />

                  <HStack w="100%">
                    <TextField
                      name="nascimento_gestor"
                      label="Data de nascimento*"
                      placeholder=""
                      textAlign="left"
                      fontSize="0.7em"
                      color="#939598"
                      backgroundColor="#EFEFEF"
                      mask="99/99/9999"
                      maskChar={null}
                      type="tel"
                      h="3.8em"
                      fontWeight="bold"
                      borderRadius="-0.5em"
                      w="16.5em"
                      autoComplete="off"
                    />

                    <SelectField
                      name="sexo_gestor"
                      placeholder="Sexo"
                      color="gray"
                      minW="100%"
                      mt="1em"
                      h="2.8em"
                      fontSize="1em"
                      backgroundColor="#EFEFEF"
                      variant="outline"
                      textAlign="left"
                      fontWeight="normal"
                      onChange={(e) => setSexo(e)}
                      options={['Masculino', 'Feminino']}
                      autoComplete="off"
                    />
                  </HStack>

                  <HStack flexDirection={'row'} w="100%">
                    {/* <TextField
                      name="cpf_gestor"
                      label="CPF*"
                      placeholder=""
                      mask="999.999.999-99"
                      textAlign="left"
                      fontSize="0.7em"
                      color="#939598"
                      backgroundColor="#EFEFEF"
                      maskChar={null}
                      type="tel"
                      h="3.8em"
                      fontWeight="bold"
                      borderRadius="-0.5em"
                      autoComplete="off"
                    /> */}

                    <TextField
                      name="crm_gestor"
                      label="N° CRM"
                      textAlign="left"
                      fontSize="0.7em"
                      placeholder=""
                      color="#939598"
                      backgroundColor="#EFEFEF"
                      maskChar={null}
                      type="tel"
                      mask="999999999"
                      h="3.8em"
                      fontWeight="bold"
                      borderRadius="-0.5em"
                      autoComplete="off"
                      maxLength={50}
                    />

                    <TextField
                      name="uf_gestor"
                      label="UF*"
                      placeholder=""
                      textAlign="left"
                      fontSize="0.7em"
                      color="#939598"
                      backgroundColor="#EFEFEF"
                      maskChar={null}
                      type="text"
                      h="3.8em"
                      fontWeight="bold"
                      borderRadius="-0.5em"
                      autoComplete="off"
                      maxLength={2}
                    />
                  </HStack>

                  <HStack w="100%">
                    <TextField
                      name="whatsapp_gestor"
                      label="Whatsapp*"
                      placeholder=""
                      textAlign="left"
                      fontSize="0.7em"
                      color="#939598"
                      backgroundColor="#EFEFEF"
                      maskChar={null}
                      mask="(99) 99999-9999"
                      type="tel"
                      h="3.8em"
                      fontWeight="bold"
                      borderRadius="-0.5em"
                      autoComplete="off"
                    />

                    <HStack w="70%" pt="1em">
                      <Switch
                        name="copiarwpp"
                        color="gray"
                        size="lg"
                        mt="0.4em"
                        lineHeight="-1em"
                        fontSize="0.7em"
                        onChange={(e) => {
                          e.target.checked ? mesmoDaClinica(e.target.checked, setFieldValue) : validarWhatsapp(e.target.checked, setFieldValue);
                        }}
                      ></Switch>
                      <Text color="gray" fontSize="0.8em" align="left">
                        {' '}
                        O mesmo da clínica!
                      </Text>
                    </HStack>
                  </HStack>

                  <HStack w="100%">
                    <InputGroup>
                      <TextField
                        name="senha_gestor"
                        label="Senha de acesso*"
                        placeholder="Senha de acesso*"
                        textAlign="left"
                        fontSize="0.7em"
                        color="#939598"
                        backgroundColor="#EFEFEF"
                        maskChar={null}
                        type={show ? 'text' : 'password'}
                        h="3.8em"
                        fontWeight="bold"
                        borderRadius="-0.5em"
                        required
                      />

                      <InputRightElement mt="13%">
                        <Button size="sm" onClick={handleClick} leftIcon={show ? <ViewIcon /> : <ViewOffIcon />}></Button>
                      </InputRightElement>
                    </InputGroup>

                    <InputGroup>
                      <TextField
                        name="senha_confirmar"
                        label="Confirme sua senha*"
                        placeholder="Confirme sua senha*"
                        textAlign="left"
                        fontSize="0.7em"
                        color="#939598"
                        backgroundColor="#EFEFEF"
                        maskChar={null}
                        type={show ? 'text' : 'password'}
                        h="3.8em"
                        fontWeight="bold"
                        borderRadius="-0.5em"
                        required
                      />
                      <InputRightElement mt="13%">
                        <Button h="1.75rem" size="sm" onClick={handleClick} leftIcon={show ? <ViewIcon /> : <ViewOffIcon />}></Button>
                      </InputRightElement>
                    </InputGroup>
                  </HStack>

                  <ButtonGroup flexDirection={'column'} w="70%" pt="15%">
                    <Button backgroundColor="#95F3EE" color="#5AB2AD" h="3em" type="submit" borderRadius="2em" >
                      Seguir
                    </Button>
                  </ButtonGroup>
                </VStack>
              </VStack>
            );
          }}
        </Formik>
      </VStack>
    </>
  );
}

export default GestordaClinica;
