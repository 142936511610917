import {
    Center,
    Heading,
    chakra,
    Modal,
    ModalBody,
    ModalCloseButton,
    ModalContent,
    ModalOverlay,
    Stack,
    Button,
    ModalFooter,
    Text,
    VStack,
    ButtonGroup,
    useToast,
} from '@chakra-ui/react';
import React, { useContext, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { AccountContext } from '../../../AccountContext';

function ModalReenvioExames(props) {
    const disclosure = props.disclosure;
    const { atendimento } = props
    const { setUser, user } = useContext(AccountContext);
    const navigate = useNavigate();
    const toast = useToast();
    const [disabledButton, setDisabledButton] = useState(false)

    function callToast(title, description, status) {
        toast({
            title: title,
            description: description,
            status: status,
            duration: 4000,
            isClosable: true,
            position: 'top',
            colorScheme: 'red',
            containerStyle: {
                paddingTop: '50px',
            },
        })
    }

    function reenviarWhatsapp() {
        const paciente_data = {
            paciente_id: atendimento.paciente_id,
            itens: atendimento.itens,
            nome: atendimento.paciente_nome,
            ambulatorio_orcamento_id: atendimento.ambulatorio_orcamento_id,
            empresa_id: user.empresa_id,
            operador_id: user.operador_id,
        }

        fetch(`${process.env.REACT_APP_BASE_URL_API_SERVER}/schedule/reSendrequestExam`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                credentials: 'include',
            },
            body: JSON.stringify(paciente_data),
        }).then(async (response) => {
            if (response.status === 200) {
                callToast('Importante', 'Mensagem reenviada com sucesso', 'success')
            } else {
                callToast('Importante', 'Ocorreu um erro ao tentar reenviar a solicitação', 'error')
            }
            disclosure.onClose()
        })
    }
    return (
        <>
            <Modal isOpen={disclosure.isOpen} isCentered onClose={disclosure.onClose}>
                <ModalOverlay />
                <ModalContent maxW="85%" borderRadius="2EM">
                    <ModalCloseButton />
                    <ModalBody>
                        <Center>
                            <Heading textAlign="center" color="red" mt="5" size="md">
                                Aviso
                            </Heading>
                        </Center>
                        <chakra.h4 mt="0.2em" color="#808080" textAlign="center" fontSize="1.2em">
                            Deseja reenviar a solicitação por whatsapp? As mensagens e solicitações apareceram novamente para o paciente.
                        </chakra.h4>
                        <VStack>
                            <ButtonGroup flexDirection={'row'} pt="15%">
                                <Button
                                    backgroundColor=" red "
                                    type="submit"
                                    onClick={() => {
                                        setDisabledButton(true)
                                        reenviarWhatsapp()
                                    }}
                                    w="60%"
                                    color="#ffff"
                                    h="3em"
                                    borderRadius="0.8em"
                                >
                                    Reenviar
                                </Button>

                                <Button
                                    backgroundColor="#4D9140"
                                    type="submit"
                                    onClick={() => { disclosure.onClose() }}
                                    w="75%"
                                    color="#ffff"
                                    h="3em"
                                    borderRadius="0.8em"
                                >
                                    Cancelar
                                </Button>
                            </ButtonGroup>
                        </VStack>
                    </ModalBody>
                </ModalContent>
            </Modal>

        </>
    )
}

export default ModalReenvioExames