import React from 'react'
import voltar from '../../assets/images/v.svg';
import { Button, ButtonGroup, Image, VStack, chakra } from '@chakra-ui/react';
import { useLocation, useNavigate } from 'react-router-dom';

function SenhaAlteradaFinal() {
    const navigate = useNavigate();
  return (
    
    <> 
    <VStack align="center" backgroundColor="#E0F0EF" h="100vh" pt={{ md: '30%', sm: '10%' }}>
        <Image mr="18em" src={voltar} onClick={() => navigate(-1)} size="lg"></Image>{' '}
            <VStack w="75%" pt="60%">
                <chakra.p color="#318681" fontSize={'1em'} fontWeight={'bold'} paddingBottom="0.5em" textAlign={'left'}>
                    Prontinho! 
                </chakra.p>
                <chakra.p color="#318681" fontSize={'1em'} fontWeight={'bold'} paddingBottom="0.5em">
                    Sua senha foi alterada 
                </chakra.p>

                <ButtonGroup flexDirection={'column'} pt={{ md: '16em', sm: '35%' }} w="100%">
                    <Button backgroundColor="#5AB2AD" color="#FFFFFF" h="2.9em" type="submit" borderRadius="0.8em" onClick={() => navigate('/login')}>
                        Voltar para o login
                    </Button>
                </ButtonGroup>
                
            </VStack>
        </VStack>
    </>
  )
}

export default SenhaAlteradaFinal