import {
  Button,
  ButtonGroup,
  Heading,
  Image,
  Modal,
  ModalBody,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Text,
  VStack,
} from '@chakra-ui/react';
import React from 'react';
import png from '../../../../assets/images/nao-cadastrado.svg';
import sair from '../../../../assets/images/sairModal.svg';
import { useNavigate } from 'react-router-dom';

function NenhumMedicoCadastrado(prop) {
  const disclosure = prop.disclosure;
  const navigate = useNavigate();
  return (
    <>
      <Modal isOpen={disclosure.isOpen} isCentered>
        <ModalOverlay />
        <ModalContent height="350px" width="350px" borderRadius="1.5em">
          <ModalHeader>
            <Image ml="280" src={sair} size="lg" onClick={disclosure.onClose}></Image>
          </ModalHeader>

          <ModalBody pt="1.5em">
            <Image ml="120" src={png} size="lg"></Image>
            <Heading size="md" ml="3" mt="15">
              <Text fontSize="1em" lineHeight="1em" color="#939598" fontWeight={'bold'} textAlign={'center'} noOfLines={5} maxWidth="100%">
                Você ainda não tem médico cadastrado. Para utilizar todas as funcionados do aplicativo, cadastre agora!
              </Text>
            </Heading>
            <VStack>
              <ButtonGroup flexDirection={'row'} pt="10%">
                <Button
                  backgroundColor="#4D9140"
                  type="submit"
                  onClick={() => navigate('/medicos/listagem')}
                  w="100%"
                  color="#ffff"
                  h="3em"
                  borderRadius="0.8em"
                >
                  Cadastrar
                </Button>
              </ButtonGroup>
            </VStack>
          </ModalBody>
          <VStack ml="4">
            <ModalFooter></ModalFooter>
          </VStack>
        </ModalContent>
      </Modal>
    </>
  );
}

export default NenhumMedicoCadastrado;
