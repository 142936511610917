import {
    Center,
    Heading,
    chakra,
    Modal,
    ModalBody,
    ModalCloseButton,
    ModalContent,
    ModalOverlay,
    Stack,
    Button,
    ModalFooter,
    Text,
    VStack,
    ButtonGroup,
    useToast,
} from '@chakra-ui/react';
import React, { useContext } from 'react';
import { AccountContext } from '../../../AccountContext';
import { useLocation, useNavigate } from 'react-router-dom';

function ExcluirConta(props) {
    const disclosure = props.disclosure;
    const { setUser, user } = useContext(AccountContext);
    const navigate = useNavigate();
    const toast = useToast();

    function showToast() {
        toast({
            title: '',
            position: 'bottom',
            description: 'Conta excluida com sucesso. Em caso de dúvidas contato suporte através do número 0800 280 2133',
            status: 'success',
            duration: 2000,
            isClosable: true,
            containerStyle: {
                paddingBottom: '50px',
            },
        });
    }

    const deleteAccount = async () => {
        let response = await fetch(`${process.env.REACT_APP_BASE_URL_API_SERVER}/clients/delete_client/${user.username}/${user.empresa_id}`, {
            headers: { 'Content-Type': 'application/json' },
            method: 'GET',
        });
        const postsData = await response.json();
        if (postsData.message === 'sucesso') {
            showToast();
            await fetch(`${process.env.REACT_APP_BASE_URL_API_SERVER}/auth/logout`, {
                method: 'GET',
                credentials: 'include',
            }).then(() => {
                navigate('/');
            });
        } else {
            console.log(postsData);
        }
    };

    return (
        <>
            <Modal isOpen={disclosure.isOpen} isCentered onClose={disclosure.onClose}>
                <ModalOverlay />
                <ModalContent maxW="85%" borderRadius="2EM">
                    <ModalCloseButton />
                    <ModalBody>
                        <Center>
                            <Heading textAlign="center" color="red" mt="5" size="md">
                                Aviso
                            </Heading>
                        </Center>
                        <chakra.h4 mt="0.2em" color="#808080" textAlign="center" fontSize="1.2em">
                            Ao excluir sua conta, sua empresa, usuário e médicos serão excluídos permanentemente.
                        </chakra.h4>
                        <VStack>
                            <ButtonGroup flexDirection={'row'} pt="15%">
                                <Button
                                    backgroundColor=" red "
                                    type="submit"
                                    onClick={() => { deleteAccount(); }}
                                    w="60%"
                                    color="#ffff"
                                    h="3em"
                                    borderRadius="0.8em"
                                >
                                    Excluir
                                </Button>

                                <Button
                                    backgroundColor="#4D9140"
                                    type="submit"
                                    onClick={() => { disclosure.onClose() }}
                                    w="75%"
                                    color="#ffff"
                                    h="3em"
                                    borderRadius="0.8em"
                                >
                                    Cancelar
                                </Button>
                            </ButtonGroup>
                        </VStack>
                    </ModalBody>
                </ModalContent>
            </Modal>
        </>
    )
}

export default ExcluirConta