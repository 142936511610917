import { Button, ButtonGroup, Flex, HStack, Text, useDisclosure, useToast, VStack } from '@chakra-ui/react';
import { Form, Formik } from 'formik';
import React, { useEffect } from 'react';
import { useContext } from 'react';
import { useState } from 'react';
import { useLocation } from 'react-router-dom';
import { AccountContext } from '../../AccountContext';
import ActionChoice from '../../Utils/Drawer/ExameAction';
import DoctorHeader from '../../Utils/Headers/DoctorHeader';
import TextField from '../../Utils/Inputs/TextField';
import ConfirmacaoWhatsappPaciente from '../../Utils/Modal/Cadastro/ConfirmacaoWhatsappPaciente';
import * as Yup from 'yup';

function ConfereDados() {
  const { state } = useLocation();
  const { paciente } = state;
  const actionDisclosure = useDisclosure();
  const whatsappDisclousure = useDisclosure();
  const { user, setUser, setPage } = useContext(AccountContext);
  const [validaWhatsapp, setValidaWhatsapp] = useState(false);
  const [whatsapp_campo, setWhatsapp] = useState(false);
  setPage('Paciente');
  const toast = useToast();
  function callToast(title, description, status) {
    toast({
      title: title,
      description: description,
      status: status,
      duration: 4000,
      isClosable: true,
      position: 'top',
      colorScheme: 'red',
      containerStyle: {
        paddingTop: '50px',
      },
    });
  }
  function atualizaWhatsapp(wpp, nome, cpf, paciente_id) {
    const vals = {
      whatsapp: wpp,
      paciente_id: paciente_id,
    };
    fetch(`${process.env.REACT_APP_BASE_URL_API_SERVER}/clients/updatePacienteWhatsapp`, {
      method: 'POST',
      credentials: 'include',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(vals),
    })
      .then(async (response) => {
        return response.json();
      })
      .then(async (resposta) => {
        if (resposta.message !== 'failed') {
          callToast('Importante', 'Sucesso ao atualizar o contato do paciente', 'success');
        } else {
          callToast('Importante', 'Falha ao atualizar o contato do paciente. Tente novamente', 'error');
        }
      });
  }

  const onlyNumbers = (str) => str?.replace(/[^0-9]/g, '');

  const paciente_data = {
    whatsapp: whatsapp_campo,
    cpf: paciente.cpf,
    paciente_id: paciente.paciente_id,
    nome: paciente.nome,
  };


  function validaEmpresa() {
    if ((user?.empresa_valida !== true && user?.empresa_valida !== false) || user?.empresa_valida === undefined || user?.empresa_valida === null) {
      fetch(`${process.env.REACT_APP_BASE_URL_API_SERVER}/payment/checkRecipientData`, {
        method: 'POST',
        credentials: 'include',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ cnpj: user.cnpj }),
      })
        .then(async (resposta) => {
          return resposta.json();
        })
        .then((retorno) => {
          setUser((prevState) => ({
            ...prevState,
            empresa_valida: retorno,
          }));
        });
    }
  }

  useEffect(() => {
    validaEmpresa();
  }, []);

  return (
    <>
      <DoctorHeader />
      <ConfirmacaoWhatsappPaciente disclosure={whatsappDisclousure} paciente_data={paciente_data} />
      <ActionChoice disclosure={actionDisclosure} />
      {paciente && (
        <>
          <VStack w="100%" mt="5%">
            <Formik
              initialValues={{
                cpf: paciente.cpf,
                nome: paciente.nome,
                nascimento: paciente?.nascimento ? paciente?.nascimento.split('T')[0].split('-')?.reverse().join('-') : '',
                whatsapp: paciente.whatsapp,
              }}
              validationSchema={Yup.object({
                whatsapp: Yup.string()
                  .required('Whatsapp é obrigatório')
                  .trim()
                  .matches(/^\(?[1-9]{2}\)? ?(?:[2-8]|9[1-9])[0-9]{3}\-?[0-9]{4}$/, 'Whatsapp Inválido'),
              })}
              onSubmit={(values, actions) => {
                if (onlyNumbers(values.whatsapp) < 8) {
                  callToast('Importante', 'Preencha o campo de whatsapp corretamente', 'error');
                  return;
                }
                if (validaWhatsapp) {
                  atualizaWhatsapp(onlyNumbers(values.whatsapp), paciente.nome, paciente.cpf, paciente.paciente_id);
                }
                actionDisclosure.onOpen();
              }}
            >
              {(props) => {
                const { setFieldValue } = props;
                return (
                  <VStack as={Form} w="90%">
                    <TextField
                      name="cpf"
                      label="CPF do paciente*"
                      placeholder="000.000.000-00"
                      textAlign="center"
                      fontSize="0.7em"
                      color="#000"
                      backgroundColor="#EFEFEF"
                      mask="999.999.999-99"
                      maskChar={null}
                      type="tel"
                      autoComplete="off"
                      isDisabled="true"
                    />

                    <TextField
                      name="nome"
                      label="Nome*"
                      placeholder="Nome"
                      textAlign="center"
                      fontSize="0.7em"
                      color="#000"
                      backgroundColor="#EFEFEF"
                      maskChar={null}
                      type="text"
                      autoComplete="off"
                      isDisabled="true"
                    />

                    <TextField
                      name="nascimento"
                      label="Data de nascimento*"
                      placeholder="Data de nascimento"
                      textAlign="center"
                      fontSize="0.7em"
                      color="#000"
                      backgroundColor="#EFEFEF"
                      mask="99/99/9999"
                      maskChar={null}
                      type="text"
                      autoComplete="off"
                      isDisabled="true"
                    />
                    <TextField
                      name="whatsapp"
                      label="Whatsapp*"
                      placeholder="Digite o whatsapp do paciente"
                      textAlign="center"
                      fontSize="0.7em"
                      color="#000"
                      backgroundColor="#EFEFEF"
                      mask="(99) 99999-9999"
                      maskChar={null}
                      type="tel"
                      autoComplete="off"
                      onChange={(e) => {
                        setFieldValue('whatsapp', onlyNumbers(e.target.value));
                        if (onlyNumbers(e.target.value) !== onlyNumbers(paciente.whatsapp)) {
                          setValidaWhatsapp(true);
                        }
                      }}
                      // isDisabled="true"
                    />
                    <ButtonGroup pt="10%">
                      <Button backgroundColor="#95F3EE" color="#5AB2AD" w="13em" h="3em" type="submit" borderRadius="2em">
                        Seguir
                      </Button>
                    </ButtonGroup>
                  </VStack>
                );
              }}
            </Formik>
          </VStack>
        </>
      )}
    </>
  );
}

export default ConfereDados;
