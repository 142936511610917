import { Button, ButtonGroup, Divider, Drawer, DrawerBody, DrawerContent, DrawerOverlay, Flex, Img, Table, Text } from '@chakra-ui/react';
import { useContext, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import TextField from '../../Utils/Inputs/TextField';
import LargeHeader from '../../Utils/Headers/LargeHeader';
import { AccountContext } from '../../AccountContext';
import { useEffect } from 'react';
import { BsFillPencilFill } from 'react-icons/bs';
import NoUserHeader from '../../Utils/Headers/NoUserHeader';
import Loading from '../../Utils/Loading';

export default function Main() {
  const [medicos, setMedicos] = useState();
  const { user, setPage } = useContext(AccountContext);
  const navigate = useNavigate();
  setPage('Profissionais Cadastrados');
  const getMedicos = () => {
    fetch(`${process.env.REACT_APP_BASE_URL_API_SERVER}/clients/selectOperatorCompany/${user.empresa_id}`, {
      method: 'POST',
      credentials: 'include',
      headers: {
        'Content-Type': 'application/json',
      },
    })
      .catch((err) => {
        console.log(err);
        return;
      })
      .then((response) => {
        if (!response || !response.ok || response.status >= 400) {
          return;
        }
        return response.json();
      })
      .then((result) => {
        setMedicos(result.data);
      });
  };
  useEffect(() => {
    getMedicos();
  }, []);

  const useMedicos = medicos?.map((medico) => {
    const name = medico.nome;
    const crm = medico.conselho;
    const uf = medico.uf;
    function EditarCadastroMedico() {
      navigate('/medicos/editarCadastroMedico', {
        state: {
          ...medico,
        },
      });
    }
    return (
      <>
        <tr>
          <td>{name}</td>
        </tr>
        <tr>
          <td>
            {crm ? `CRM - ${crm} UF - ${uf}` : ' '}

          </td>
          <td borderBottom="1px solid black">
            <BsFillPencilFill
              onClick={() => {
                EditarCadastroMedico();
              }}
            />
          </td>
        </tr>

        <Divider w={'105%'} colorScheme="blackAlpha" />
      </>
    );
  });
  return (
    <>
      <NoUserHeader />
      {medicos ? (
        <>
          <Table w={'90%'} ml="2em">
            {useMedicos}
          </Table>
          <ButtonGroup flexDirection={'column'} w={{ md: '20em', sm: '15em' }} ml={{ md: '2.5em', sm: '4em' }} mt={{ md: '15em', sm: '1em' }}>
            <Button backgroundColor="#95F3EE" color="#5AB2AD" h="3em" type="submit" borderRadius="2em" onClick={() => navigate('/medicos/cadastro')}>
              Cadastrar novo
            </Button>
          </ButtonGroup>
        </>
      ) : (
        <>
          <Loading />
        </>
      )}
    </>
  );
}
