import { useContext, useEffect, useState } from 'react';
import { Route, Routes } from 'react-router-dom';
import { AccountContext } from './AccountContext';

import Login from './Auth/Login';
import SelectEmpresa from './Auth/SelectEmpresa'

//utils
import SucessoGenerico from './Utils/Alerts/Sucesso';
import ErroGenerico from './Utils/Alerts/Error';
import PrivateRoutes from './PrivateRoutes';
import Loading from './Utils/Loading';

// dashboard
import Main from './Dashboard/Main/Main';
//routes
//página inicial
import Opening from './Auth/Opening';
//cadastro da clínica do médico
import Cadastro from './Cadastro/CadastroClinica';
import CNPJ from './Cadastro/ValidacaoCNPJ';
import CPF from './Cadastro/ValidacaoCPF';
import Novasenha from './Cadastro/Novasenha';
import DadosClinicos from './Cadastro/DadosClinicos';
import Gestor from './Cadastro/GestordaClinica';
import RepasseFinanceiroClinica from './Cadastro/RepasseFinanceiroClinica';
import ValidacaoBirdId from './Dashboard/Main/BirdId/ValidacaoBirdId';
import Recuperacao from './Cadastro/RecuperacaoSenha';
import MensagemRecuperacao from './Cadastro/MensagemRecuperacao';
import SenhaAlteradaFinal from './Cadastro/SenhaAlteradaFinal';

//Empresa Operador
import MudarEmpresa from './Menu/Empresa/Main'

//Medicos
import ListaMedicos from './Menu/Medicos/Main';
import CadastroMedico from './Menu/Medicos/CadastroMedico';
import EditarCadastroMedico from './Menu/Medicos/EditarMedicoCadastro';
import DadosBancariosClinica from './Menu/Medicos/DadosBancariosClinica';
import EditarDadosClinica from './Menu/Medicos/EditarDadosClinica';
import EditarEnderecoClinica from './Menu/Medicos/EditarEnderecoClinica';
import MainDrawer from './Menu/MainDrawer';
import ListaHistorico from './Menu/Medicos/ListaHistoricos'
import CadastroModelo from './Menu/Medicos/CadastroModelo'
import DetalhesHistorico from './Menu/Medicos/DetalhesHistorico'

//solicitação
import ConferePaciente from './Dashboard/Main/ConferePaciente';
import SolicitarExames from './Dashboard/Main/Exame/Main';
import Prescrever from './Dashboard/Main/Prescricao/Main';
import Atestado from './Dashboard/Main/Atestados/Main';
import AreaLivre from './Dashboard/Main/Atestados/AreaLivre'
import { VStack } from '@chakra-ui/react';

//financeiro
import MainFinanceiro from './Menu/Financeiro/Main';
import Consulta from './Dashboard/Main/Consultas/Consulta';
import EscolheProcedimento from './Dashboard/Main/Consultas/EscolheProcedimento';
function Views() {
  const { user, menuDisclosure } = useContext(AccountContext);
  useEffect(() => {
    if (user.perfil_id !== 4 && user.qntdeMedico <= 0) {
      menuDisclosure.onOpen();
    }
  }, [user]);
  return user.loggedIn === null ? (
    <VStack w="100%">
      <Loading />
    </VStack>
  ) : (
    <Routes>
      <Route path="/" element={<Login />} />
      <Route path="/select-login" element={<SelectEmpresa />} />
      <Route path="/mudar-empresa" element={<MudarEmpresa />} />
      <Route path="/abertura" element={<Opening />} />
      <Route path="/novasenha" element={<Novasenha />} />
      <Route path="/login" element={<Login />} />
      <Route path="/cadastro" element={<Cadastro />} />
      <Route path="/mensagemRecuperacao" element={<MensagemRecuperacao />} />
      <Route path="/CNPJ" element={<CNPJ />} />
      <Route path="/CPF" element={<CPF />} />
      <Route path="/dadosclinicos" element={<DadosClinicos />} />
      <Route path="/gestordaclinica" element={<Gestor />} />
      <Route path="/recuperacao" element={<Recuperacao />} />
      <Route path="/repassefinanceiro" element={<RepasseFinanceiroClinica />} />
      <Route path="/validacaobird" element={<ValidacaoBirdId />} />
      <Route path="/alerts/sucesso/:rota" element={<SucessoGenerico />} />
      <Route path="/solicitacao/confere-paciente" element={<ConferePaciente />} />
      <Route path="/solicitacao/exame" element={<SolicitarExames />} />
      <Route path="/solicitacao/prescricao" element={<Prescrever />} />
      <Route path="/solicitacao/atestado" element={<Atestado />} />
      <Route path="/solicitacao/area-livre" element={<AreaLivre />} />
      <Route path="/medicos/listagem" element={<ListaMedicos />} />
      <Route path="/medicos/historico" element={<ListaHistorico />} />
      <Route path="/medicos/modelo-atestado" element={<CadastroModelo />} />
      <Route path="/medicos/historico/detalhes" element={<DetalhesHistorico />} />
      <Route path="/medicos/cadastro" element={<CadastroMedico />} />
      <Route path="/medicos/editarCadastroMedico" element={<EditarCadastroMedico />} />
      <Route path="/medicos/dadosbancariosclinica" element={<DadosBancariosClinica />} />
      <Route path="/medicos/editardadosclinica" element={<EditarDadosClinica />} />
      <Route path="/medicos/editarenderecoclinica" element={<EditarEnderecoClinica />} />
      <Route path="/financeiro" element={<MainFinanceiro />} />
      <Route path="/MainDrawer" element={<MainDrawer />} />
      <Route path="/senhaalteradafinal" element={<SenhaAlteradaFinal />} />

      <Route path="/consulta" element={<Consulta />} />
      <Route path="/consulta/agendamento" element={<EscolheProcedimento />} />

      <Route element={<PrivateRoutes />}>
        <Route path="/dashboard" element={<Main />} />
      </Route>
      <Route path="*" element={<Login />} />
    </Routes>
  );
}

export default Views;
