import { ErrorBoundary } from 'react-error-boundary';
import ErrorFallback from './ErrorFallback';
import './App.css';
import UserContext from './components/AccountContext';
import Views from './components/Views';

function App() {
  return (
    <UserContext>
      <ErrorBoundary FallbackComponent={ErrorFallback}>
        <Views />
      </ErrorBoundary>
    </UserContext>
  );
}

export default App;
