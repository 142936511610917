import {
  Button,
  Heading,
  Image,
  Modal,
  ModalBody,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Stack,
  Text,
  VStack,
} from '@chakra-ui/react';
import React, { useContext, useState } from 'react';
import png from '../../../../assets/images/nao-cadastrado.svg';
import sair from '../../../../assets/images/sairModal.svg';
import { AccountContext } from '../../../AccountContext';
import { useNavigate } from 'react-router-dom';
function DesejaSair(prop) {
  const disclosure = prop.disclosure;
  const { user, setUser } = useContext(AccountContext);
  const [sair, setSair] = useState(false);
  const navigate = useNavigate();
  const handleLogout = async () => {
    try {
      await fetch(`${process.env.REACT_APP_BASE_URL_API_SERVER}/auth/logout`, {
        method: 'GET',
        credentials: 'include',
      });
    } catch (error) {
      console.error(error.message);
    }
  };
  return (
    <>
      <Modal isOpen={disclosure.isOpen} onClose={sair} isCentered>
        <ModalOverlay />
        <ModalContent height="250px" width="280px" borderRadius="0.8em">
          <ModalBody pt="2.5em">
            <Heading size="md" ml="3" mt="10">
              <Text fontSize="1em" lineHeight="1em" color="#939598" fontWeight={'bold'} textAlign={'center'} noOfLines={5} maxWidth="100%">
                Deseja Sair?
              </Text>
            </Heading>
            <VStack></VStack>
          </ModalBody>
          <VStack ml="4">
            <ModalFooter>
              <Stack direction="row" ml="-4" mt="-5" spacing={5} align="center">
                <Button
                  colorScheme="teal"
                  variant="outline"
                  size="lg"
                  onClick={() => {
                    setUser({ loggedIn: false });
                    handleLogout();
                    navigate('/dashboard');
                  }}
                >
                  Sim
                </Button>
                <Button
                  colorScheme="teal"
                  variant="solid"
                  size="lg"
                  onClick={() => {
                    setSair(true);
                    disclosure.onClose(true);
                  }}
                >
                  Não
                </Button>
              </Stack>
            </ModalFooter>
          </VStack>
        </ModalContent>
      </Modal>
    </>
  );
}

export default DesejaSair;
