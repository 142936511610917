import { Heading, Image, Modal, ModalBody, ModalContent, ModalFooter, ModalHeader, ModalOverlay, Text, VStack } from '@chakra-ui/react';
import React from 'react';
import png from '../../../../assets/images/nao-cadastrado.svg';
import sair from '../../../../assets/images/sairModal.svg';
function PacienteNaoEncontrado(prop) {
  const disclosure = prop.disclosure;
  return (
    <>
      <Modal isOpen={disclosure.isOpen} onClose={disclosure.onClose} isCentered>
        <ModalOverlay />
        <ModalContent height="350px" width="350px" borderRadius="1.5em">
          <ModalHeader>
            <Image ml="280" src={sair} size="lg" onClick={disclosure.onClose}></Image>
          </ModalHeader>

          <ModalBody pt="2.5em">
            <Image ml="120" src={png} size="lg"></Image>
            <Heading size="md" ml="3" mt="15">
              <Text fontSize="1em" lineHeight="1em" color="#939598" fontWeight={'bold'} textAlign={'center'} noOfLines={5} maxWidth="100%">
                Paciente não encontrado. Preencha os campos com os dados correspondentes.
              </Text>
            </Heading>
            <VStack></VStack>
          </ModalBody>
          <VStack ml="4">
            <ModalFooter></ModalFooter>
          </VStack>
        </ModalContent>
      </Modal>
    </>
  );
}

export default PacienteNaoEncontrado;
