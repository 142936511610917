import { Button, ButtonGroup, Flex, Text, VStack, useToast } from '@chakra-ui/react'
import { Field, Form, Formik } from 'formik';
import React, { useContext, useEffect, useRef, useState } from 'react';
import DoctorHeader from '../../../Utils/Headers/DoctorHeader'
import * as Yup from 'yup';
import { parse } from 'date-fns';
import { AccountContext } from '../../../AccountContext';
import { useLocation } from 'react-router-dom';

function AreaLivre() {
    const textAreaRef = useRef(null);
    const handleInput = () => {
        const textArea = textAreaRef.current;
        textArea.style.height = 'auto';
        textArea.style.height = `${textArea.scrollHeight}px`;
    };
    const toast = useToast();
    const { user, setUser, setPage } = useContext(AccountContext);
    const [arealivre, setAreaLivre] = useState();
    setPage('Área Livre');
    const { state } = useLocation();

    function callToast(title, description, status) {
        toast({
          title: title,
          description: description,
          status: status,
          duration: 4000,
          isClosable: true,
          position: 'bottom',
          colorScheme: 'red',
          containerStyle: {
            paddingTop: '50px',
          },
        })
      }

    function calcularIdade(dataNascimento) {
        const hoje = new Date();
        const nascimento = new Date(dataNascimento);

        let idade = hoje.getFullYear() - nascimento.getFullYear();
        const mesAtual = hoje.getMonth();
        const diaAtual = hoje.getDate();
        const mesNascimento = nascimento.getMonth();
        const diaNascimento = nascimento.getDate();

        if (mesAtual < mesNascimento || (mesAtual === mesNascimento && diaAtual < diaNascimento)) {
            idade--;
        }

        return idade;
    }
    const idade = calcularIdade(state.paciente.nascimento)
    useEffect(() => {
        if (arealivre && arealivre.length > 0) {
            setUser((prevState) => ({
                ...prevState,
                cart: {
                    ...user.cart,
                    arealivre:
                        prevState.cart && prevState.cart.arealivre && prevState.cart.arealivre.length
                            ? [...prevState.cart.arealivre, ...arealivre]
                            : [...arealivre],
                },
            }));
            setAreaLivre([]);
        }
    }, [arealivre]);

    return (
        <>
            <DoctorHeader />
            <VStack w="100%" mt="5%">
                <Formik
                    initialValues={{ descricao: '' }}
                    validationSchema={Yup.object({})}
                    onSubmit={(values, actions) => {
                        if (values.descricao === undefined || values?.descricao?.length <= 0) {
                            toast({
                                title: 'Importante',
                                position: 'bottom',
                                description: 'O campo descrição é obrigatório!',
                                status: 'error',
                                duration: 2000,
                                isClosable: true,
                                containerStyle: {
                                    paddingBottom: '3em',
                                },
                            });
                            return
                        }
                        callToast('Sucesso', 'Prescrição livre adicionado com sucesso!', 'success')
                        setAreaLivre((prevState) => {
                            const newState = prevState ? [...prevState] : [];
                            newState.push(values);
                            return newState;
                        });
                        actions.resetForm();
                        // toast({
                        //     title: 'Atestado Adicionado',
                        //     position: 'bottom',
                        //     description: '',
                        //     status: 'success',
                        //     duration: 2000,
                        //     isClosable: true,
                        //     containerStyle: {
                        //         paddingBottom: '3em',
                        //     },
                        // });
                        // actions.resetForm();
                    }}
                >

                    <VStack w="90%" alignItems="center" as={Form}>
                        <VStack w="100%" bg={'#D7E7E6'} borderRadius={'1em'} mb={'2em'}>
                            <Text color="#656565" textAlign="left" w="80%" fontSize="0.8em" mb="0.5em">
                                Paciente:
                            </Text>
                            <Text color="#656565" textAlign="left" fontWeight="bold" w="80%" fontSize="0.8em" mb="0.5em">
                                {state.paciente.nome}
                            </Text>

                            <Text color="#656565" textAlign="left" w="80%" fontSize="0.8em" mb="0.5em">
                                Idade:
                            </Text>

                            <Text color="#656565" textAlign="left" fontWeight="bold" w="80%" fontSize="0.8em" mb="0.5em">
                                {idade}
                            </Text>

                            <Text color="#656565" textAlign="left" w="80%" fontSize="0.8em" mb="0.5em">
                                Sexo:
                            </Text>

                            <Text color="#656565" textAlign="left" fontWeight="bold" w="80%" fontSize="0.8em" mb="0.5em">
                                {state.paciente.sexo === 'f' ? 'Feminino' : 'Masculino'}
                            </Text>
                        </VStack>

                        <div style={{ width: '100%', position: 'relative', marginTop: '1.3em' }}>
                            <label style={{ position: 'absolute', top: '-20px', left: '10px', fontSize: '0.7em', color: '#939598', fontWeight: "bold" }}>Descrição</label>
                            <Field
                                as="textarea"
                                name="descricao"
                                placeholder="Digite aqui"
                                style={{
                                    textAlign: 'center',
                                    fontSize: '0.7em',
                                    backgroundColor: '#EFEFEF',
                                    width: '100%',
                                    minHeight: '150px',
                                    resize: 'none',
                                    overflowY: 'hidden',
                                    border: '1px solid #ccc',
                                    borderRadius: '5px',
                                    outline: 'none',
                                }}
                            />
                        </div>



                        <ButtonGroup pt="5%" w="100%" pb="5%">
                            <Flex flexDirection={'column'} w="100%" align="center">
                                <Button colorScheme="teal" w="60%" h="3em" mb="5%" type="submit" borderRadius="2em">
                                    Salvar
                                </Button>
                            </Flex>
                        </ButtonGroup>
                    </VStack>
                </Formik>
            </VStack>
        </>
    )

}

export default AreaLivre