import {
  Button,
  ButtonGroup,
  chakra,
  Center,
  Flex,
  Heading,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalOverlay,
  useToast,
  VStack,
  Stack,
  ModalFooter,
  Switch,
  useDisclosure,
  Text,
  HStack,
} from '@chakra-ui/react';
import { Form, Formik } from 'formik';
import React from 'react';
import { useState } from 'react';
import { useContext } from 'react';
import { AccountContext } from '../../../AccountContext';
import DoctorHeader from '../../../Utils/Headers/DoctorHeader';
import TextField from '../../../Utils/Inputs/TextField';
import ExameAction from '../../../Utils/Drawer/ExameAction';
import EnderecoPaciente from '../../../Utils/Modal/Dashboard/EnderecoPaciente';
import { useEffect } from 'react';
import * as Yup from 'yup';
import { useLocation } from 'react-router-dom';
function Main() {
  const { user, setUser, setPage } = useContext(AccountContext);
  setPage('Prescrição');
  const [prescricao, setPrescricao] = useState([]);
  const modalDisclosure = useDisclosure();
  const actionDisclosure = useDisclosure();
  const toast = useToast();
  const [isSwitchOn, setIsSwitchOn] = useState(false);
  const enderecoPacienteConst = useDisclosure();
  const { state } = useLocation();
  const { paciente } = state;

  useEffect(() => {
    if (prescricao && prescricao.length > 0) {
      setUser((prevState) => ({
        ...prevState,
        cart: {
          ...user.cart,
          prescricao:
            prevState.cart && prevState.cart.prescricao && prevState.cart.prescricao.length
              ? [...prevState.cart.prescricao, ...prescricao]
              : [...prescricao],
        },
      }));
      setPrescricao([]);
    }
  }, [prescricao]);

  const validationSchema = Yup.object().shape({
    droga: Yup.string().required('Campo Obrigatório'),
    // apresentacao: Yup.string().required('Campo Obrigatório'),
    // concentracao: Yup.string().required('Campo Obrigatório'),
    posologia: Yup.string().required('Campo Obrigatório'),
  });

  useEffect(() => {
    if (isSwitchOn === true) {
      const pesquisaEnderecoClient = fetch(`${process.env.REACT_APP_BASE_URL_API_SERVER}/patients/getClientByCPF/${paciente.cpf}`, {
        method: 'GET',
        credentials: 'include',
        headers: {
          'Content-Type': 'application/json',
        },
      })
        .catch((err) => {
          return err;
        })
        .then((result) => {
          if (
            (result && result.data && result.data.cep === null) ||
            (result && result.data && result.data.cep === '') ||
            (result && result.data && result.data.cep === undefined)
          ) {
            enderecoPacienteConst.onOpen();
          }
        });
    }
  }, [isSwitchOn]);

  function callToast() {
    toast({
      title: 'Importante',
      description: `Preencha pelo menos um campo para prescrever.`,
      status: 'error',
      duration: 4000,
      isClosable: true,
      position: 'bottom',
      colorScheme: 'red',
      containerStyle: {
        paddingTop: '50px',
      },
    })
  }

  return (
    <>
      <DoctorHeader />

      <ExameAction type={'EXAME'} disclosure={actionDisclosure} />
      <EnderecoPaciente disclosure={enderecoPacienteConst} />
      <Modal isOpen={modalDisclosure.isOpen} isCentered onClose={modalDisclosure.onClose}>
        <ModalOverlay />
        <ModalContent maxW="85%" borderRadius="2EM">
          <ModalCloseButton />
          <ModalBody>
            <Center>
              <Heading textAlign="center" color="#808080" mt="5" size="md">
                Deseja prescrever mais medicamentos?{' '}
              </Heading>
            </Center>
            <chakra.h4 mt="0.2em" color="#808080" textAlign="center" fontSize="0.8em">
              Antes de prosseguir, adicione todos os medicamentos que deseja prescrever.
            </chakra.h4>
          </ModalBody>
          <Center>
            <Stack direction="column" spacing={4} align="center">
              <Button
                bgColor="teal"
                mt="5%"
                _hover="none"
                color="white"
                w="88%"
                size="lg"
                borderRadius="0.6em"
                autofocus="true"
                onClick={() => {
                  modalDisclosure.onClose();
                }}
              >
                Adicionar mais medicamentos
              </Button>
              <Button
                bgColor="#5AB2AD"
                color="white"
                _hover="none"
                w="88%"
                size="lg"
                borderRadius="0.6em"
                onClick={() => {
                  modalDisclosure.onClose();
                  actionDisclosure.onOpen();
                }}
              >
                Já selecionei todos
              </Button>
            </Stack>
          </Center>
          <ModalFooter></ModalFooter>
        </ModalContent>
      </Modal>
      <VStack w="100%" mt="5%">
        <Formik
          initialValues={{ droga: '', apresentacao: '', concentracao: '', posologia: '' }}
          validationSchema={validationSchema}
          onSubmit={(values, actions) => {
            const vals = { ...values, especial: isSwitchOn };

            if (vals.droga <= 0 && vals.apresentacao <= 0 && vals.concentracao <= 0 && vals.posologia <= 0) {
              callToast()
              return
            }

            setPrescricao((prevState) => [...prevState, vals]);

            toast({
              title: 'Prescrição Adicionada',
              position: 'bottom',
              description: '',
              status: 'success',
              duration: 2000,
              isClosable: true,
              containerStyle: {
                paddingBottom: '3em',
              },
            });
            actions.resetForm();
          }}
        >
          <VStack as={Form} w="90%">
            <TextField
              name="droga"
              label="DROGA*"
              placeholder="Digite Aqui"
              textAlign="center"
              fontSize="0.7em"
              color="#939598"
              backgroundColor="#EFEFEF"
              type="text"
              autoComplete="off"
            />
            <TextField
              name="apresentacao"
              label="APRESENTAÇÃO*"
              placeholder="Digite Aqui"
              textAlign="center"
              fontSize="0.7em"
              color="#939598"
              backgroundColor="#EFEFEF"
              type="text"
              autoComplete="off"
            />
            <TextField
              name="concentracao"
              label="CONCENTRAÇÃO*"
              placeholder="Digite Aqui"
              textAlign="center"
              fontSize="0.7em"
              color="#939598"
              backgroundColor="#EFEFEF"
              type="text"
              autoComplete="off"
            />
            <TextField
              name="posologia"
              label="POSOLOGIA*"
              placeholder="Digite Aqui"
              textAlign="center"
              fontSize="0.7em"
              color="#939598"
              backgroundColor="#EFEFEF"
              type="text"
              autoComplete="off"
            />
            <HStack>
              <Switch size="lg" colorScheme="teal" onChange={() => setIsSwitchOn(!isSwitchOn)} />{' '}
              <Text fontSize="sm" color="#585858">
                receita especial
              </Text>
            </HStack>
            <ButtonGroup pt="5%" w="100%" pb="5%">
              <Flex flexDirection={'column'} w="100%" align="center">
                <Button colorScheme="teal" w="60%" h="3em" mb="5%" type="submit" borderRadius="2em">
                  Adicionar
                </Button>
                <Button
                  colorScheme="teal"
                  w="60%"
                  h="3em"
                  borderRadius="2em"
                  onClick={() => {
                    modalDisclosure.onOpen();
                  }}
                >
                  Concluir
                </Button>
              </Flex>
            </ButtonGroup>
          </VStack>
        </Formik>
      </VStack>
    </>
  );
}

export default Main;
