import { useContext } from 'react';
import { AccountContext } from './AccountContext';

const { Outlet, Navigate } = require('react-router');

const useAuth = () => {
  const { user } = useContext(AccountContext);
  return user && user.loggedIn;
};

const useBird = () => {
  const { user } = useContext(AccountContext);
  if (user.ignoraBird === true) {
    return false
  } else {

    return (
      (user &&
        user.loggedIn &&
        !user.tokenBird &&
        (user.perfil_id === 4 ||
          (user.crm && user.crm !== null && user.crm !== 'null' && user.crm !== undefined && user.crm !== 'undefined' && user.crm !== ''))) ||
      (user &&
        user.tokenBird &&
        user.tokenBird !== null &&
        user.tokenBird.status >= 400 &&
        (user.perfil_id === 4 ||
          (user.crm && user.crm !== null && user.crm !== 'null' && user.crm !== undefined && user.crm !== 'undefined' && user.crm !== ''))) ||
      (user &&
        user.lastBirdDate &&
        user.lastBirdDate !== null &&
        new Date(`${user.lastBirdDate.substr(0, 19)}Z`).getTime() + 43200000 > new Date().getTime() + 108000000 &&
        (user.perfil_id === 4 ||
          (user.crm && user.crm !== null && user.crm !== 'null' && user.crm !== undefined && user.crm !== 'undefined' && user.crm !== '')))
    );
  }
};

const PrivateRoutes = () => {
  const isAuth = useAuth();
  const isBirdAuth = useBird();
  return isAuth ? isBirdAuth ? <Navigate to="/validacaobird" /> : <Outlet /> : <Navigate to="/" />;
};

export default PrivateRoutes;
