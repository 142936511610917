import {
  Button,
  Heading,
  Image,
  Modal,
  ModalBody,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Text,
  useToast,
  VStack,
} from '@chakra-ui/react';
import { Form, Formik } from 'formik';
import React, { useContext, useState } from 'react';
import png from '../../../assets/images/nao-cadastrado.svg';
import sair from '../../../assets/images/sairModal.svg';
import TextField from '../../Utils/Inputs/TextField';
import * as Yup from 'yup';
import { parse } from 'date-fns';
import validaCPF from '../../Utils/Functions/validaCPF';
import { useNavigate } from 'react-router-dom';
import { useEffect } from 'react';
import { AccountContext } from '../../AccountContext';
function PacienteNaoEncontrado(prop) {
  const { user, setUser } = useContext(AccountContext);
  const [isLoading, setLoading] = useState(false)
  const disclosure = prop.disclosure;
  const navigate = useNavigate();
  const toast = useToast();
  const cnpj = prop?.cnpj
  function showToast() {
    toast({
      title: '',
      position: 'top',
      description: 'Sistema em Manutenção',
      status: 'error',
      duration: 2000,
      isClosable: true,
      containerStyle: {
        paddingTop: '50px',
      },
    });
  }
  /* async function userLogin(usuario, senha, cnpj) {
    
     fetch(`${process.env.REACT_APP_BASE_URL_API_SERVER}/auth/login`, {
       method: 'POST',
       credentials: 'include',
       headers: {
         'Content-Type': 'application/json',
       },
       body: JSON.stringify({ username: usuario, password: senha, cnpj: cnpj }),
     })
       .catch(async (err) => {
         showToast();
         return;
       })
       .then(async (res) => {
         if (!res || !res.ok || res.status >= 400) {
           showToast();
           return;
         }
         return await res.json();
       })
       .then(async (data) => {
         setLoading(false)
         if (!data) return;
         setUser((prevState) => ({ ...prevState, ...data }));
         if (data.status) {
           //setError(data.status);
         } else if (data.loggedIn) {
           navigate('/dashboard');
         }
       });
   }; */
  return (
    <>
      <Modal isOpen={disclosure.isOpen} onClose={disclosure.onClose} isCentered>
        <ModalOverlay />
        <ModalContent height="420px" width="350px" borderRadius="1.5em">
          <ModalBody>
            <Formik
              initialValues={{ senha: '', senha2: '' }}
              validationSchema={Yup.object({
                senha: Yup.string()
                  .required('Senha é obrigatória!')
                  .min(6, 'A senha deve ter no mínimo 6 caracteres'),
                senha2: Yup.string()
                  .required('Confirmação da senha é obrigatória')
                  .min(6, 'A senha de confirmação deve ter no mínimo 6 caracteres')
                  .test('pass-match', 'Senhas não correspondem. Por favor, digite novamente', function (value) {
                    return this.parent.senha === value;
                  }),
              })}
              onSubmit={(values, actions) => {
                setLoading(true)
                const vals = { ...values };
                fetch(`${process.env.REACT_APP_BASE_URL_API_SERVER}/clients/cadastrosenhaprimeiroLogin`, {
                  method: 'POST',
                  credentials: 'include',
                  headers: { 'content-type': 'application/json' },
                  body: JSON.stringify({
                    senha: vals.senha,
                    cpf: prop.cpf,
                    cnpj: cnpj?.length > 0 ? cnpj : 0,
                  }),
                }).catch(async (err) => {
                  showToast();
                  return;
                })
                  .then(async (res) => {
                    if (!res || !res.ok || res.status >= 400) {
                      showToast();
                      return;
                    }
                    return await res.json();
                  })
                  .then((data) => {
                    setLoading(false)
                    console.log('retorno data ', data)
                    if (!data) return;
                    setUser((prevState) => ({ ...prevState, ...data }));
                    console.log(data)
                    console.log(data.loggedIn)
                    if (data.loggedIn) {
                      navigate('/dashboard');
                    }
                  });
              }}
              validateOnChange={false}
              validateOnBlur={false}
            >
              <VStack as={Form} w="100%">
                <Heading size="md" ml="3">
                  <Text
                    fontSize="1em"
                    lineHeight="1em"
                    mt={'2em'}
                    color="#939598"
                    fontWeight={'bold'}
                    textAlign={'center'}
                    noOfLines={5}
                    maxWidth="100%"
                    mb="1em"
                  >
                    Digite sua nova senha de acesso
                  </Text>
                  <TextField
                    label="Senha de acesso*"
                    name="senha"
                    textAlign="left"
                    fontSize="0.7em"
                    color="#939598"
                    backgroundColor="#EFEFEF"
                    maskChar={null}
                    type="password"
                    h="2.9em"
                    fontWeight="bold"
                    borderRadius="-0.5em"
                    w="100%"
                    autoComplete="off"
                  />
                  <TextField
                    label="Confirme a senha*"
                    name="senha2"
                    textAlign="left"
                    fontSize="0.7em"
                    color="#939598"
                    backgroundColor="#EFEFEF"
                    maskChar={null}
                    type="password"
                    h="2.9em"
                    fontWeight="bold"
                    borderRadius="-0.5em"
                    w="100%"
                    autoComplete="off"
                  />
                </Heading>
                <VStack>
                  <Button backgroundColor="#95F3EE" color="#5AB2AD" h="3em" w="15em" mt="2em" fontWeight={'bold'} type="submit" borderRadius="2em" >
                    Confirmar
                  </Button>
                </VStack>
              </VStack>
            </Formik>
          </ModalBody>
          <VStack ml="4">
            <ModalFooter></ModalFooter>
          </VStack>
        </ModalContent>
      </Modal >
    </>
  );
}

export default PacienteNaoEncontrado;
