import {
    Center,
    Heading,
    chakra,
    Modal,
    ModalBody,
    ModalCloseButton,
    ModalContent,
    ModalOverlay,
    Stack,
    Button,
    ModalFooter,
    Text,
    VStack,
    ButtonGroup,
    useToast,
} from '@chakra-ui/react';
import React, { useContext, useState } from 'react';
import { AccountContext } from '../../../AccountContext';
import { useNavigate } from 'react-router-dom';

function ConfirmaMudancaEmpresa(props) {
    const disclosure = props.disclosure
    const { user, setUser, setPage } = useContext(AccountContext);
    const toast = useToast();
    const onlyNumbers = (str) => str.replace(/[^0-9]/g, '');
    const [error, setError] = useState(null);
    const [cpf, setCpf] = useState();
    const navigate = useNavigate();

    function showToast() {
        toast({
            title: '',
            position: 'top',
            description: 'Sistema em Manutenção',
            status: 'error',
            duration: 2000,
            isClosable: true,
            containerStyle: {
                paddingTop: '50px',
            },
        });
    }

    function mudarEmpresaLogado(cnpj) {
        const vals = {
            cnpj: cnpj,
            username: user.cpf,
            password: 'mudanca-empresa',
            confirmed: true,
        }
        fetch(`${process.env.REACT_APP_BASE_URL_API_SERVER}/auth/login`, {
            method: 'POST',
            credentials: 'include',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify(vals),
        })
            .catch((err) => {
                showToast();
                return;
            })
            .then((res) => {
                if (!res || !res.ok || res.status >= 400) {
                    showToast();
                    return;
                }
                return res.json();
            })
            .then((data) => {
                if (!data) return;
                if (data.primeiro_login === false && data.perfil === 4) {
                    setCpf(onlyNumbers(data.cpf));
                    disclosure.onOpen();
                } else {
                    setUser((prevState) => ({ ...prevState, ...data }));
                    if (data.status) {
                        setError(data.status);
                    } else if (data.loggedIn) {
                        navigate('/dashboard');
                    }
                }
            });
    }


    return (
        <>
            <Modal isOpen={disclosure.isOpen} isCentered onClose={disclosure.onClose}>
                <ModalOverlay />
                <ModalContent maxW="85%" borderRadius="2EM" >
                    <ModalCloseButton />
                    <ModalBody mb={'2em'}>
                        <Center>
                            <Heading textAlign="center" color="red" mt="5" size="md">
                                Aviso
                            </Heading>
                        </Center>
                        <chakra.h4 mt="0.2em" color="#808080" textAlign="center" fontSize="1.2em">
                            Ao clicar em continuar, você será logado na empresa que selecionou. Deseja continuar?
                        </chakra.h4>
                        <VStack>
                            <ButtonGroup flexDirection={'row'} pt="15%">
                                <Button
                                    backgroundColor="#4D9140"
                                    type="submit"
                                    onClick={() => {
                                        disclosure.onClose();
                                    }}
                                    w="60%"
                                    color="#ffff"
                                    h="3em"
                                    borderRadius="0.8em"
                                >
                                    Voltar
                                </Button>

                                <Button
                                    backgroundColor="red"
                                    type="submit"
                                    onClick={() => {
                                        mudarEmpresaLogado(props.cnpj)
                                    }}
                                    w="75%"
                                    color="#ffff"
                                    h="3em"
                                    borderRadius="0.8em"
                                >
                                    Continuar
                                </Button>
                            </ButtonGroup>
                        </VStack>
                    </ModalBody>
                </ModalContent>
            </Modal>
        </>
    )
}

export default ConfirmaMudancaEmpresa
