import { Alert, AlertDescription, AlertIcon, AlertTitle, Button, VStack, HStack, Box, Heading, Text } from '@chakra-ui/react';
import { CheckCircleIcon } from '@chakra-ui/icons';
import { React, useContext, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { AccountContext } from '../../AccountContext';

import MainHeader from '../../Utils/Headers/MainHeader';
import _ from 'lodash';
export default function Sucesso() {
  const { rota } = useParams();
  const { user, setUser, page, setPage } = useContext(AccountContext);

  if (page === 'Titulo da Pagina' || page === '' || !page) {
    setPage('');
  }

  var msg = 'Operação realizada com sucesso';
  var navigateTo = '/dashboard';
  console.log(rota);
  switch (rota) {
    case 'transferencia':
      msg = 'Sua transferência foi solicitada com sucesso! se aprovado o valor será creditado na sua conta em até 48h.';
      navigateTo = '/dashboard';
      break;
    default:
  }

  function Feature({ title, desc, ...rest }) {
    return (
      <Box p={15} w="100%" shadow="xl" borderWidth="1px" borderRadius="15" {...rest}>
        <CheckCircleIcon ml="40%" mt="2" color="green" boxSize={10} />
        <Heading fontSize="xl" mt="5" textAlign="center">
          {title}{' '}
        </Heading>

        <Text mt={5} textAlign="center">
          {' '}
          <p>{msg}</p>
          <Button
            mt="10"
            borderRadius="15"
            height="48px"
            width="200px"
            border="1px"
            alignItems="center"
            onClick={() => {
              navigate(navigateTo);
            }}
          >
            OK
          </Button>
        </Text>
      </Box>
    );
  }

  const navigate = useNavigate();
  return (
    <>
      <MainHeader />
      <VStack w="90%" m="auto" justify="top" spacing="1rem" marginTop="20">
        <HStack>
          <Feature
            title="Sucesso"
            desc="You deserve good things. With a whooping 10-15% interest rate per annum, grow your savings on your own terms with our completely automated process"
          />
        </HStack>
      </VStack>
    </>
  );
}
