import {
  HStack,
  Text,
  VStack,
  Input,
  Tabs,
  TabList,
  chakra,
  Tab,
  TabPanels,
  TabPanel,
  Stack,
  Button,
  useDisclosure,
  Heading,
  useToast,
  Table,
  TableContainer,
  Tr,
  Td,
  Th,
  Thead,
  Tbody,
  Flex,
} from '@chakra-ui/react';
import React, { useContext, useState } from 'react';
import { useEffect } from 'react';
import { AccountContext } from '../../AccountContext';
import SelectField from '../../Utils/Inputs/SelectField';
import NoUserHeader from '../../Utils/Headers/NoUserHeader';
import { Form, Formik } from 'formik';
import _ from 'lodash';
import DrawerSaque from './DrawerSaque';
import Loading from '../../Loading/Loading';
import { FaCircleInfo } from 'react-icons/fa6';

function Main() {
  const { user, setPage } = useContext(AccountContext);
  setPage('Extrato');
  const [listaMedicos, setListaMedicos] = useState();
  const [medico, setMedico] = useState();
  const [listaExames, setListaExames] = useState([]);
  const [valorTotal, setValorTotal] = useState();
  const disclosureSaque = useDisclosure();
  const [empresa, setEmpresa] = useState([]);
  const [ticket, setTicketMedio] = useState(0);
  const [historico, setHistorico] = useState([]);
  const [splitValor, setSplitValor] = useState(0);
  const [refetch, setRefetch] = useState(false);
  const [balance, setBalance] = useState(false);
  const toast = useToast();
  const arrayNomesMedicos = listaMedicos ? listaMedicos.map((item) => item.nome) : [];

  function callToast(title, description, status) {
    toast({
      title: title,
      description: description,
      status: status,
      duration: 10000,
      isClosable: true,
      position: 'bottom',
      colorScheme: 'red',
      containerStyle: {
        paddingTop: '30px',
      },
    });
  }

  // async function fetchValorMedico(url) {
  //   console.log(url)
  //   try {
  //     fetch(`${process.env.REACT_APP_BASE_URL_API_SERVER}/schedule/fetchValorMedico/`, {
  //       method: 'POST',
  //       credentials: 'include',
  //       headers: {
  //         'Content-Type': 'application/json',
  //       },
  //       body: JSON.stringify({ medico: user.perfil_id === 4 ? true : false, empresa_id: user.empresa_id, operador_id: user.operador_id }),
  //     })
  //       .then(async (response) => {
  //         return response.json();
  //       })
  //       .then(async (reposta) => {
  //         console.log(reposta.data)
  //         setSplitValor(reposta.data);
  //       });
  //   } catch (error) {
  //     console.log(error.erro)
  //   }
  // }
  const getValorTotal = async () => {
    const vals = {
      medico: user.perfil_id === 4 ? true : false,
      empresa_id: user.empresa_id,
      operador_id: user.operador_id,
    };
    fetch(`${process.env.REACT_APP_BASE_URL_API_SERVER}/schedule/get_valor_total/`, {
      method: 'POST',
      credentials: 'include',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(vals),
    })
      .then(async (response) => {
        return response.json();
      })
      .then(async (reposta) => {
        setValorTotal(reposta);
      });
  };
  async function fetchData(url) {
    try {
      let response = await fetch(url, {
        headers: { 'Content-Type': 'application/json' },
        method: 'POST',
      });
      const postsData = await response.json();

      setEmpresa(postsData?.data);
    } catch (error) { }
  }
  async function fetchBalance(url) {
    try {
      let response = await fetch(url, {
        headers: { 'Content-Type': 'application/json' },
        method: 'GET',
      });
      const postsData = await response.json();

      setBalance(postsData?.data);
    } catch (error) { }
  }
  async function fetchTicket(url) {
    try {
      const vals = {
        medico: user.perfil_id === 4 ? true : false,
        empresa_id: user.empresa_id,
        operador_id: user.operador_id,
      };
      fetch(`${process.env.REACT_APP_BASE_URL_API_SERVER}/schedule/getTicketMedioEmpresa/`, {
        method: 'POST',
        credentials: 'include',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(vals),
      })
        .then(async (response) => {
          return response.json();
        })
        .then(async (reposta) => {
          setTicketMedio(reposta.data);
        });
    } catch (error) { }
  }

  async function fetchHistorico(url) {
    try {
      const vals = {
        medico: user.perfil_id === 4 ? true : false,
        empresa_id: user.empresa_id,
        operador_id: user.operador_id,
      };
      fetch(`${process.env.REACT_APP_BASE_URL_API_SERVER}/schedule/getHistoricoEmpresa/`, {
        method: 'POST',
        credentials: 'include',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(vals),
      })
        .then(async (response) => {
          return response.json();
        })
        .then(async (reposta) => {

          setHistorico(reposta.data);
        });
    } catch (error) { }
  }

  const refetchData = () => {
    setRefetch(!refetch);
  };



  useEffect(() => {
    getValorTotal();
    fetchData(`${process.env.REACT_APP_BASE_URL_API_SERVER}/clients/selectEmpresarById/${user.empresa_id}`);
    fetchBalance(`${process.env.REACT_APP_BASE_URL_API_SERVER}/schedule/getBalance/${user.recipient_id}`);
    fetchTicket(`${process.env.REACT_APP_BASE_URL_API_SERVER}/schedule/getTicketMedioEmpresa/${user.empresa_id}`);
    fetchHistorico(`${process.env.REACT_APP_BASE_URL_API_SERVER}/schedule/getHistoricoEmpresa/${user.empresa_id}`);
    // fetchValorMedico();
  }, [medico, refetch]);

  const valorDivisao = ticket?.vlr_total !== undefined ? ticket[0]?.vlr_total / ticket[0]?.qtd : 0.0;
  const valorDisponivel = valorTotal?.data[0] > 0 || valorTotal?.data[0] !== undefined ? valorTotal?.data[0]?.vlr_disponivel : 0.0;

  const valorReceber = valorTotal?.data[0] !== undefined ? valorTotal?.data[0]?.vlr_antecipado : 0.0;
  const valorAtendido = valorTotal?.data[0] !== undefined ? valorTotal?.data[0]?.vlr_total : 0.0;

  const GroupedRender = ({ appointments }) => {
    const groupedAppointments = {};
    appointments.forEach((appointment) => {
      const date = appointment.data.split('T')[0];
      const key = `${date}_${appointment.nome_paciente}`;

      if (!groupedAppointments[key]) {
        groupedAppointments[key] = {
          patient: appointment.nome_paciente,
          date: date,
          appointments: [],
        };
      }

      groupedAppointments[key].appointments.push(appointment);
    });

    return Object.values(groupedAppointments)?.map((item, index) => {
      let valor_cashback = 0;
      item.appointments.map((dados) => {
        valor_cashback += Number(dados.vlr_original);
      });
      const valorFormatado = valor_cashback?.toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' });

      return (
        <>
          <Flex borderRadius="10px" shadow="md" mt="1em" width="95%" backgroundColor="#F0F0F0" key={index}>
            <VStack ml="1em" align="start" w="100%" mt="1em">
              <FaCircleInfo
                color="teal"
                onClick={() => {
                  callToast('Atenção', 'Os valores podem variar de acordo com as taxas dependendo da forma de pagamento, bandeira e provedora de serviço do paciente.', 'error');
                }}

              />
              <Text w="100%" numbberOfLines={1}>
                <chakra.span fontWeight={'bold'}>Data do Atendimento:</chakra.span>{' '}
                <chakra.span>{item?.date?.split('-').reverse().join('/')}</chakra.span>
              </Text>
              <Text w="100%" numbberOfLines={1}>
                <chakra.span fontWeight={'bold'}>Paciente:</chakra.span> <chakra.span>{item.patient}</chakra.span>
              </Text>
              <Text w="100%" numbberOfLines={1}>
                <chakra.span fontWeight={'bold'}>Procedimentos:</chakra.span>
                <chakra.ul ml="5">
                  {item.appointments.map((appointment) => {
                    return <chakra.li>{appointment.procedimento}</chakra.li>;
                  })}
                </chakra.ul>
              </Text>

              <Text w="100%" numbberOfLines={1}>
                <chakra.span fontWeight={'bold'}>Cashback:</chakra.span>
                <chakra.ul ml="5">
                  {valorFormatado}
                </chakra.ul>
              </Text>
            </VStack>
          </Flex>
        </>
      );
    });
  };
  function validaFinanceiro(acao) {
    disclosureSaque.onOpen();
  }

  // const useHistorico = historico?.map((item) => {
  //   const data = new Date(item.data);
  //   const dia = String(data.getDate()).padStart(2, '0');
  //   const mes = String(data.getMonth() + 1).padStart(2, '0');
  //   const ano = data.getFullYear();
  //   return (
  //     <>
  //       <HStack w="100%" p="3%" border={'1px solid #5AB2AD'} borderRadius={'0.8em'}>
  //         <VStack align="start" w="100%" h="8em">
  //           <Text w="100%">
  //             <b>Procedimento: </b>{item.procedimento}
  //           </Text>
  //           <Text w="100%">
  //             <b>Paciente: </b>{item.nome_paciente}
  //           </Text>
  //           <Text w="100%">
  //             <b>Data: </b>{dia}/{mes}/{ano}
  //           </Text>
  //           <Text w="100%" >
  //             <b>Valor pago: </b>{parseFloat(item.vlr_total).toLocaleString('pt-br', { style: 'currency', currency: 'BRL' })}
  //           </Text>
  //         </VStack>
  //       </HStack >
  //     </>
  //   );
  // });

  if (empresa) {
    const bankAccountTypesPagarme = ['conta_corrente', 'conta_poupanca', 'conta_corrente_conjunta', 'conta_poupanca_conjunta'];
    const bankAccountTypes = ['Conta corrente', 'Conta poupança', 'Conta corrente conjunta', 'Conta poupança conjunta'];

    const indiceCorrespondente = bankAccountTypesPagarme.indexOf(empresa[0]?.tipoconta);

    let tipo_conta = '';
    if (indiceCorrespondente !== -1) {
      tipo_conta = bankAccountTypes[indiceCorrespondente];
    } else {
      tipo_conta = 'Conta não encontrada';
    }

    const ticketMedio = valorDivisao?.toLocaleString('pt-BR', {
      style: 'currency',
      currency: 'BRL',
    });

    const valor_disponivel = valorDisponivel?.toLocaleString('pt-BR', {
      style: 'currency',
      currency: 'BRL',
    });

    const valor_receber = valorReceber?.toLocaleString('pt-BR', {
      style: 'currency',
      currency: 'BRL',
    });

    const valor_atendido = valorAtendido?.toLocaleString('pt-BR', {
      style: 'currency',
      currency: 'BRL',
    });
    return valorTotal ? (
      <>
        <NoUserHeader />
        {valorTotal ? (
          <DrawerSaque
            disclosure={disclosureSaque}
            disponivel={valor_disponivel > balance?.available?.amount / 100 ? balance?.available?.amount / 100 : valor_disponivel}
            antecipar={valorReceber}
            update={refetchData}
          />
        ) : null}
        <VStack w="100%">
          <Tabs w="100%" minH="50vh" maxH="50vh" defaultIndex={0} colorScheme="twitter">
            <TabList w="100%">
              <Tab w="50%">Financeiro</Tab>
              <Tab w="50%">Extrato</Tab>
            </TabList>
            <TabPanels>
              <TabPanel w="100%">
                <VStack>
                  {/*<HStack w="100%">
                    <VStack height={'6em'} backgroundColor={'#f5f5f5'} w={'100%'} borderRadius={'0.8em'} p="0.8em" direction={['column']}>
                      <Text w="100%" borderRight="1px solid #939598" textAlign={'center'} fontWeight={'bold'}>
                        Ticket Médio
                      </Text>
                      <Text w="100%" borderRight="1px solid #939598" textAlign={'center'}>
                        {ticketMedio > 0 ? 'R$ ' + ticketMedio : 'R$ 0,00'}
                      </Text>
                    </VStack>
                  </HStack>*/}
                  {/* <HStack w="100%">
                    <VStack height={'6em'} backgroundColor={'#f5f5f5'} w={'100%'} borderRadius={'0.8em'} p="0.8em" direction={['column']}>
                      <Text w="100%" borderRight="1px solid #939598" textAlign={'center'} fontWeight={'bold'}>
                        <FaCircleInfo
                          color="teal"
                          onClick={() => {
                            callToast('Total Atendido', 'Soma total dos valores de procedimentos que já foram pagos e realizados.');
                          }}
                        />
                        Total atendido:
                      </Text>
                      <Text w="100%" borderRight="1px solid #939598" textAlign={'center'}>
                        {valor_atendido > 0 ? 'R$ ' + valor_atendido : 'R$ 0,00'}
                      </Text>
                    </VStack>
                  </HStack> */}
                  <VStack backgroundColor={'#f5f5f5'} w={'100%'} borderRadius={'0.8em'} p="0.8em" direction={['column']}>
                    <Text w="100%" borderRight="1px solid #939598" textAlign={'center'} fontWeight={'bold'}>
                      <FaCircleInfo
                        color="teal"
                        onClick={() => {
                          callToast(
                            'Valores a Receber',
                            'Após o pagamento efetuado pelo paciente, pode demorar alguns dias até que o saldo seja liberado para saque dependendo da forma de pagamento utilizada pelo paciente( cartão de crédito até 30 dias e pix até 24horas após o atendimento).'
                          );
                        }}
                      />{' '}
                      A receber:
                    </Text>
                    <Text w="100%" borderRight="1px solid #939598" textAlign={'center'}>
                      {balance
                        ? (
                          balance?.available?.amount / 100 +
                          balance.waiting_funds.amount / 100 -
                          (valor_disponivel && valor_disponivel > 0 ? valor_disponivel : 0)
                        ).toLocaleString('pt-BR', {
                          style: 'currency',
                          currency: 'BRL',
                        })
                        : '0,00'}
                    </Text>
                  </VStack>
                  <HStack w="100%">
                    <VStack height={'8em'} backgroundColor={'#f5f5f5'} w={'100%'} borderRadius={'0.8em'} p="0.8em" direction={['column']}>
                      <Text w="100%" borderRight="1px solid #939598" textAlign={'center'} fontWeight={'bold'}>
                        <FaCircleInfo
                          color="teal"
                          onClick={() => {
                            callToast(
                              'Disponível para Saque',
                              'O valor sacado é transferido para a sua conta via TED. Se sacado até às 17h, o dinheiro entra na conta no mesmo dia, caso contrário só no próximo dia útil. Será cobrado uma taxa de R$ 3,67 por saque.'
                            );
                          }}
                        />
                        Disponível para Saque
                      </Text>
                      <Text w="100%" borderRight="1px solid #939598" textAlign={'center'}>
                        R${' '}
                        {!valor_disponivel ||
                          valor_disponivel === undefined ||
                          valor_disponivel === 'undefined' ||
                          valor_disponivel === null ||
                          valor_disponivel === 'null' ||
                          valor_disponivel === '' ||
                          valorTotal?.data[0] === 0 ||
                          valorTotal?.data[0] === '0'
                          ? '0,00'
                          : valor_disponivel > balance?.available?.amount / 100
                            ? balance?.available?.amount / 100
                            : valor_disponivel}
                      </Text>
                      <Button
                        h="2em"
                        w="80%"
                        backgroundColor="#5AB2AD"
                        color="white"
                        onClick={() => {
                          validaFinanceiro('saque');
                        }}
                      >
                        Sacar
                      </Button>
                    </VStack>
                  </HStack>
                  <HStack w="100%"></HStack>
                </VStack>
              </TabPanel>
              <TabPanel w="100%">
                <VStack w="100%">
                  {historico && historico.length > 0 ? (
                    <GroupedRender appointments={historico} a={'das'} />
                  ) : (
                    <>
                      <VStack w="90%">
                        <Text>Sem informações disponíveis no momento</Text>
                      </VStack>
                    </>
                  )}
                </VStack>
              </TabPanel>
            </TabPanels>
          </Tabs>
        </VStack>
      </>
    ) : (
      <Loading />
    );
  } else {
    return (
      <>
        <Heading>Cadastre uma conta bancária</Heading>
      </>
    );
  }
}

export default Main;
