import { Text, useDisclosure, VStack } from '@chakra-ui/react';
import { IoCloseCircleOutline } from 'react-icons/io5';
import React, { useContext } from 'react';
import { AccountContext } from '../../AccountContext';

function LargeHeader() {
  const { user, menuDisclosure } = useContext(AccountContext);
  return (
    <>
      <VStack align="start" pl="1.5em" pt="5em" w="full" shadow="md" h="13em" backgroundColor="#5AB2AD">
        {user.perfil_id !== 4 && user.qntdeMedico <= 0 ? (
          <></>
        ) : (
          <IoCloseCircleOutline size="2rem" color="white" onClick={() => menuDisclosure.onClose()} />
        )}
        <VStack w="100%" spacing={-2}>
          <Text casing="capitalize" pt="1em" color="white" fontWeight="bold" w="100%" fontSize="sm">
            {user.empresa ? user.empresa.toLowerCase() : ''}
          </Text>
          <Text casing="capitalize" pt="1em" fontSize="0.8em" color="white" w="100%" noOfLines="1">
            {user.empresa_endereco ? user.empresa_endereco.toLowerCase() : ''}
          </Text>
        </VStack>
      </VStack>
    </>
  );
}
//if()
export default LargeHeader;
