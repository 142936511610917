import { Box, Drawer, DrawerBody, DrawerContent, DrawerOverlay, Flex, Img, Text, useDisclosure } from '@chakra-ui/react';
import { useContext, useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { AccountContext } from '../AccountContext';
import LargeHeader from '../Utils/Headers/LargeHeader';
import MoneyBag from '../../assets/images/icons/menu-lateral/moneybag.svg';
import Money from '../../assets/images/icons/menu-lateral/money.svg';
import WriteIt from '../../assets/images/icons/menu-lateral/writeit.svg';
import Doctor from '../../assets/images/icons/menu-lateral/doctoresteto.svg';
import Logoff from '../../assets/images/icons/menu-lateral/logoff.svg';
import NenhumMedicoCadastrado from '../Utils/Modal/Dashboard/NenhumMedicoCadastrado';
import SairDoSistema from '../Utils/Modal/Dashboard/DesejaSair';
import Historico from '../../assets/images/historia.png'
import { BsClipboardPlus } from "react-icons/bs";
import { AiOutlineFileAdd } from "react-icons/ai";
import { AiOutlineUnorderedList } from "react-icons/ai";
import { CgOptions } from "react-icons/cg";

function MenuDrawer(props) {
  const disclosure = props.disclosure;
  const { user, setUser } = useContext(AccountContext);
  const [dados, setDados] = useState([])
  if (user.perfil_id === 4 && !user.menuMedico) {
    disclosure.onClose()
  }
  const navigate = useNavigate();
  const naoEncontradoDisclosure = useDisclosure();
  const sairDoSistemaDisclosure = useDisclosure();
  const handleLogout = async () => {
    try {
      await fetch(`${process.env.REACT_APP_BASE_URL_API_SERVER}/auth/logout`, {
        method: 'GET',
        credentials: 'include',
      });
    } catch (error) {
      console.error(error.message);
    }
  };

  function empresasVinculadas() {
    const vals = {
      username: user.cpf
    }
    fetch(`${process.env.REACT_APP_BASE_URL_API_SERVER}/auth/empresasVinculadas`, {
      method: 'POST',
      credentials: 'include',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(vals),
    })
      .catch((err) => {
        return;
      })
      .then((res) => {
        if (!res || !res.ok || res.status >= 400) {
          return;
        }
        return res.json();
      }).then(async (resposta) => {
        setDados(resposta)
      })
  }

  useEffect(() => {
    if (user.perfil_id !== 4 && user.qntdeMedico <= 0) {
      naoEncontradoDisclosure.onOpen();
    }
    empresasVinculadas()
  }, [user]);
  return (
    <>
      <NenhumMedicoCadastrado disclosure={naoEncontradoDisclosure} />
      <SairDoSistema disclosure={sairDoSistemaDisclosure} />
      <Drawer placement={'left'} onClose={disclosure.onClose} isOpen={disclosure.isOpen} size="full">
        <DrawerOverlay />
        <DrawerContent>
          <LargeHeader />
          <DrawerBody p="0">
            {(user.perfil_id === 1 || user.perfil_id === 10) && (
              <>
                <Flex
                  lineHeight="4em"
                  w="100%"
                  h="4em"
                  color="#808080"
                  borderBottom="1px solid #D9D9D9"
                  onClick={() => {
                    if (user.perfil_id !== 4 && user.qntdeMedico <= 0) {
                      naoEncontradoDisclosure.onOpen();
                    } else {
                      navigate('/financeiro');
                    }
                  }}
                >
                  <Img src={MoneyBag} w="2em" pl="0.5em"></Img>
                  <Text w="80%" pl="5%">
                    Ganhos da Clínica
                  </Text>
                  <Text textAlign="right" w="10%" pr="5%">
                    {'>'}
                  </Text>
                </Flex>
              </>
            )}
            <Flex
              lineHeight="4em"
              w="100%"
              h="4em"
              color="#808080"
              borderBottom="1px solid #D9D9D9"
              onClick={() => navigate('/medicos/historico')}
              alignItems="center"
              ml={'0.3em'}
            >
              <BsClipboardPlus size={30} color={'teal'} />
              <Box w="80%" pl="5%">
                <Text>Histórico</Text>
              </Box>
              <Box textAlign="right" w="10%" pr="5%">
                <Text>{'>'}</Text>
              </Box>
            </Flex>

            {/* <Flex
              lineHeight="4em"
              w="100%"
              h="4em"
              color="#808080"
              borderBottom="1px solid #D9D9D9"
              onClick={() => navigate('/medicos/modelo-atestado')}
              alignItems="center"
              ml={'0.3em'}
            >
              <AiOutlineUnorderedList size={30} color={'teal'} />
              <Box w="80%" pl="5%">
                <Text>Cadastro Modelo</Text>
              </Box>
              <Box textAlign="right" w="10%" pr="5%">
                <Text>{'>'}</Text>
              </Box>
            </Flex> */}

            {(user.perfil_id === 1 || user.perfil_id === 10) && (
              <>
                <Flex
                  lineHeight="4em"
                  w="100%"
                  h="4em"
                  color="#808080"
                  borderBottom="1px solid #D9D9D9"
                  onClick={() => {
                    if (user.perfil_id !== 4 && user.qntdeMedico <= 0) {
                      naoEncontradoDisclosure.onOpen();
                    } else {
                      navigate('/medicos/dadosbancariosclinica');
                    }
                  }}
                >
                  <Img src={Money} w="2em" pl="0.5em"></Img>
                  <Text w="80%" pl="5%">
                    Editar dados de Pagamento
                  </Text>
                  <Text textAlign="right" w="10%" pr="5%">
                    {'>'}
                  </Text>
                </Flex>
                <Flex
                  lineHeight="4em"
                  w="100%"
                  h="4em"
                  color="#808080"
                  borderBottom="1px solid #D9D9D9"
                  onClick={() => navigate('/medicos/listagem')}
                >
                  <Img src={Doctor} w="2em" pl="0.5em"></Img>
                  <Text w="80%" pl="5%">
                    Profissionais Cadastrados
                  </Text>
                  <Text textAlign="right" w="10%" pr="5%">
                    {'>'}
                  </Text>
                </Flex>
                <Flex
                  lineHeight="4em"
                  w="100%"
                  h="4em"
                  color="#808080"
                  borderBottom="1px solid #D9D9D9"
                  onClick={() => {
                    if (user.perfil_id !== 4 && user.qntdeMedico <= 0) {
                      naoEncontradoDisclosure.onOpen();
                    } else {
                      navigate('/medicos/editardadosclinica');
                    }
                  }}
                >
                  <Img src={WriteIt} w="2em" pl="0.5em"></Img>
                  <Text w="80%" pl="5%">
                    Editar Cadastro
                  </Text>
                  <Text textAlign="right" w="10%" pr="5%">
                    {'>'}
                  </Text>
                </Flex>
              </>
            )}
            {dados?.length > 1 ? (
              <>
                <Flex
                  lineHeight="4em"
                  w="100%"
                  h="4em"
                  color="#808080"
                  borderBottom="1px solid #D9D9D9"
                  onClick={() => navigate('/mudar-empresa')}
                  alignItems="center"
                  ml={'0.3em'}
                >
                  <CgOptions size={30} color={'teal'} />
                  <Box w="80%" pl="5%">
                    <Text>Mudar empresa</Text>
                  </Box>
                  <Box textAlign="right" w="10%" pr="5%">
                    <Text>{'>'}</Text>
                  </Box>
                </Flex>
              </>) : (<></>)}


            <Flex
              w="100%"
              h="4em"
              lineHeight="4em"
              color="#808080"
              borderBottom="1px solid #D9D9D9"
              onClick={() => {
                sairDoSistemaDisclosure.onOpen();
              }}
            >
              <Img src={Logoff} w="2em" pl="0.5em"></Img>
              <Text w="80%" pl="5%">
                Sair do Aplicativo
              </Text>
              <Text textAlign="right" w="10%" pr="5%">
                {'>'}
              </Text>
            </Flex>
          </DrawerBody>
        </DrawerContent>
      </Drawer>
    </>
  );
}

export default MenuDrawer;
