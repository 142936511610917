import {
  Button,
  useToast,
  ButtonGroup,
  Heading,
  Text,
  VStack,
  chakra,
  Img,
  Input,
  Center,
  Grid,
  GridItem,
  Switch,
  FormLabel,
  Flex,
  HStack,
  FormControl,
} from '@chakra-ui/react';
import Voltar from '../../assets/images/voltar.svg';
import { React, useContext, useState } from 'react';
import { AccountContext } from '../AccountContext';
import { useLocation, useNavigate } from 'react-router-dom';
import { Form, Formik } from 'formik';
import TextField from '../Utils/Inputs/TextField';
import Medicos from '../../assets/images/medicos.png';
import NoUserHeader from '../Utils/Headers/NoUserHeader';
import * as Yup from 'yup';
import { parse } from 'date-fns';
import SelectField from '../Utils/Inputs/SelectField';
import SelectSearch from '../Utils/Inputs/SelectFieldSearch2';
import validaCPF from '../Utils/Functions/validaCPF';
import { useEffect } from 'react';
import Loading from '../Utils/Loading';
import validaCNPJ from '../Utils/Functions/validaCNPJ';

function DadosClinicos() {
  const { setUser, setPage } = useContext(AccountContext);
  const [error, setError] = useState(null);
  const navigate = useNavigate();
  const toast = useToast();
  const { state } = useLocation();
  const [pixType, setPixType] = useState(null);
  const [loading, setLoading] = useState(true);
  const [bankData, setBankData] = useState(false);
  const onlyNumbers = (str) => str?.replace(/[^0-9]/g, '');

  const defaultArgs = { borderRadius: -0.5, w: '100%', maskChar: null, disabled: bankData ? true : false };
  const defaultArgsSelect = { textAlign: 'left', fontWeight: 'normal', variant: 'outline', onChange: () => { } };
  const [banks, setBanks] = useState();
  const [documento_validacao, setDocumentacao] = useState(false);
  const listbanks = banks ? banks.map((bank) => bank.nome) : [];

  let pixInputType;
  let pixInputMask;
  switch (pixType) {
    case 'CPF':
      pixInputType = 'tel';
      pixInputMask = '999.999.999-99';
      break;
    case 'CNPJ':
      pixInputType = 'tel';
      pixInputMask = '99.999.999/9999-99';
      break;
    case 'E-mail':
      pixInputType = 'email';
      pixInputMask = '';
      break;
    case 'Telefone':
      pixInputType = 'tel';
      pixInputMask = '(99) 99999-9999';
      break;
    default:
      pixInputType = 'text';
      pixInputMask = '';
      break;
  }

  let pixKeyTypes;
  pixKeyTypes = ['CNPJ', 'E-mail', 'Telefone', 'Aleatória'];
  const [documento, setValue] = useState('');
  const bankAccountTypesPagarme = ['conta_corrente', 'conta_poupanca', 'conta_corrente_conjunta', 'conta_poupanca_conjunta'];
  const bankAccountTypes = ['Conta corrente', 'Conta poupança', 'Conta corrente conjunta', 'Conta poupança conjunta'];

  setPage('Cadastro');
  function showToast() {
    toast({
      title: '',
      position: 'top',
      description: 'Sistema em Manutenção',
      status: 'error',
      duration: 2000,
      isClosable: true,
      containerStyle: {
        paddingTop: '50px',
      },
    });
  }
  const userLogin = () => {
    fetch(`${process.env.REACT_APP_BASE_URL_API_SERVER}/auth/login`, {
      method: 'POST',
      credentials: 'include',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({ username: state.cpf_gestor, password: state.senha_gestor, cnpj: state.documento, confirmed: true }),
    })
      .catch((err) => {
        showToast();
        return;
      })
      .then((res) => {
        if (!res || !res.ok || res.status >= 400) {
          showToast();
          return;
        }
        return res.json();
      })
      .then((data) => {
        if (!data) return;
        setUser((prevState) => ({ ...prevState, ...data }));
        if (data.status) {
          setError(data.status);
        } else if (data.loggedIn) {
          navigate('/dashboard');
        }
      });
  };

  async function getDatas() {
    const urlBanks = process.env.REACT_APP_BASE_URL_API_SERVER + '/banks/';
    const requestBanks = await fetch(urlBanks)
      .then((res) => {
        if (!res || !res.ok || res.status >= 400) {
          showToast();
          return;
        }
        return res.json();
      })
      .then((result) => {
        if (result.success) {
          setBanks(result.data);
        } else {
          setError('problema ao cadastrar, tente novamente mais tarde');
        }
        setLoading(false);
      });
  }

  /*

  }*/

  useEffect(() => {
    getDatas();
  }, []);

  async function postEstablishment(payload) {
    setError(false);
    const url = `${process.env.REACT_APP_BASE_URL_API_SERVER}/clients/register/`;
    const options = {
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify(payload),
    };

    try {
      const operatorData = await fetch(url, options);
      const operator = await operatorData.json();
      console.log(operator);
      if (operator.result.success === true) {
        setLoading(false);
        userLogin();
      } else {
        setError(true);
        setLoading(false);
        setError('Falha ao Cadastrar');
      }
    } catch (err) {
      setError('Falha ao Cadastrar');
      setLoading(false);
    }
  }

  async function onSubmit(values) {
    setLoading(true);
    const payload = {
      ...state,
      banco: banks[values.banco].codigo,
      tipo_conta: bankAccountTypesPagarme[values.tipo_conta],
      titular: values.titular,
      numero_conta: values.numero_conta,
      agencia: values.agencia,
      cnpj_conta: onlyNumbers(values.cnpj_conta),
      digito_validador: values.digito_validador,
    };
    postEstablishment(payload);
  }
  function validaChavePix() {
    switch (pixType) {
      case 'CPF':
        return Yup.string()
          .required('Campo obrigatório')
          .trim()
          .test('valida_cpf', 'Cpf inválido', (cpf) => validaCPF(cpf));
      case 'E-mail':
        return Yup.string().trim().email('O email digitado é inválido').required('Campo obrigatório');
      case 'Telefone':
        return Yup.string()
          .required('Campo obrigatório')
          .trim()
          .matches(/^\(?[1-9]{2}\)? ?(?:[2-8]|9[1-9])[0-9]{3}\-?[0-9]{4}$/, 'Telefone Inválido');
      default:
        return Yup.string().required('Campo obrigatório');
    }
  }

  function validateCNPJ(cnpj) {

    cnpj = cnpj.replace(/[^\d]+/g, '');

    if (cnpj == '') return false;

    if (cnpj.length != 14)
      return false;

    // Eliminate known invalid CNPJs
    if (cnpj == "00000000000000" ||
      cnpj == "11111111111111" ||
      cnpj == "22222222222222" ||
      cnpj == "33333333333333" ||
      cnpj == "44444444444444" ||
      cnpj == "55555555555555" ||
      cnpj == "66666666666666" ||
      cnpj == "77777777777777" ||
      cnpj == "88888888888888" ||
      cnpj == "99999999999999")
      return false;

    // Validate DVs
    let size = cnpj.length - 2
    let numbers = cnpj.substring(0, size);
    let digits = cnpj.substring(size);
    let sum = 0;
    let pos = size - 7;
    for (let i = size; i >= 1; i--) {
      sum += numbers.charAt(size - i) * pos--;
      if (pos < 2)
        pos = 9;
    }
    let result = sum % 11 < 2 ? 0 : 11 - sum % 11;
    if (result != digits.charAt(0))
      return false;

    size = size + 1;
    numbers = cnpj.substring(0, size);
    sum = 0;
    pos = size - 7;
    for (let i = size; i >= 1; i--) {
      sum += numbers.charAt(size - i) * pos--;
      if (pos < 2)
        pos = 9;
    }
    result = sum % 11 < 2 ? 0 : 11 - sum % 11;
    if (result != digits.charAt(1))
      return false;

    return true;

  }

  const mesmoDaClinica = (isChecked, setFieldValue) => {
    const cnpj_formatado = state.documento.replace(/^(\d{2})(\d{3})(\d{3})(\d{4})(\d{2})$/, '$1.$2.$3/$4-$5');
    if (isChecked) {
      setDocumentacao(true)
      setFieldValue('cnpj_conta', cnpj_formatado);
    } else {
      setDocumentacao(false)
      setFieldValue('cnpj_conta', documento);
    }
  };


  function applyMask(documento) {
    const cleanedValue = documento.replace(/\D/g, '');
    let maskedValue = '';
    if (cleanedValue.length <= 11) {
      maskedValue = cleanedValue.replace(/(\d{3})(\d{3})(\d{3})(\d{2})/, '$1.$2.$3-$4');
    } else if (cleanedValue.length > 11) {
      maskedValue = cleanedValue.replace(/(\d{2})(\d{3})(\d{3})(\d{4})(\d{2})/, '$1.$2.$3/$4-$5');
    } else {
      maskedValue = cleanedValue;
    }

    return maskedValue
  }

  return (
    <>

      <NoUserHeader />
      {banks && listbanks ? (
        <VStack>
          {' '}
          <Formik
            initialValues={{
              tipo_conta: '',
              titular: '',
              documento: '',
              numero_conta: '',
              digito_validador: '',
            }}
            validateOnChange={false}
            validationSchema={Yup.object({
              banco: Yup.number().required('Banco é obrigatório!'),
              tipo_conta: Yup.string().required('Tipo conta é obrigatório!'),
              titular: Yup.string()
                .required('O nome do titular é obrigatório!')
                .matches(/^[A-Za-zÀ-ÖØ-öø-ÿ\s0-9']+$/, 'O nome do titular não deve conter caracteres especiais, exceto números')
                .max(30, 'O nome do titular não pode ter mais de 30 caracteres'),

              cnpj_conta: Yup.string()
                .required('Documento é obrigatório')
                .test('validacao-documento', 'Documento Inválido', function (value) {
                  if (!value) return false;

                  const cleanedValue = value.replace(/\D/g, '');

                  const validaLetras = /^[0-9]+$/;

                  if (!validaLetras.test(cleanedValue)) {
                    return this.createError({ message: 'O documento deve conter apenas números' });
                  }

                  if (cleanedValue.length === 11) {
                    const cpfRegex = /^\d{3}\.\d{3}\.\d{3}-\d{2}$/;
                    return validaCPF(cleanedValue);
                  } else {
                    const cnpjRegex = /^\d{14}$/;
                    return cnpjRegex.test(cleanedValue);
                  }
                })
                .min(8, 'O documento deve ter no mínimo 11 dígitos'),

              numero_conta: Yup.string()
                .matches(/^[0-9]+$/, 'Número da conta deve conter apenas números')
                .required('Número da conta é obrigatório!')
                .max(13, 'O número da conta não pode ter mais de 13 caracteres'),
              digito_validador: Yup.string()
                .matches(/^[0-9]+$/, 'Dígito validador deve conter apenas números')
                .required('Dígito validador é obrigatório!')
                .max(2, 'O dígito validador não pode ter mais de 2 caracteres'),
              agencia: Yup.string()
                .matches(/^[0-9]+$/, 'Agência deve conter apenas números')
                .required('Agência é obrigatório!')
                .max(4, 'Agência não pode conter mais de 4 caracteres'),
            })}
            validateOnBlur={false}
            onSubmit={onSubmit}
          >
            {(props) => {
              const { setFieldValue } = props;
              return (
                <VStack as={Form} w="100%">
                  <VStack w="90%" textAlign={'center'}>
                    <Text fontSize="1em" lineHeight="1em" fontWeight={'bold'} textAlign={'left'} color="#5AB2AD" noOfLines={3} maxWidth="90%">
                      Dados bancários para repasse dos rendimentos da clínica.
                    </Text>
                    <Text color="red">{error && error !== null ? error : ''}</Text>
                    <FormControl>
                      <FormLabel htmlFor="titular" fontSize="0.7em" color="#939598" fontWeight="bold">
                        Banco*
                      </FormLabel>
                      <SelectSearch
                        {...defaultArgs}
                        {...defaultArgsSelect}
                        name="banco"
                        placeholder="Banco"
                        options={listbanks}
                        fontSize="0.7em"
                        backgroundColor="#EFEFEF"
                        w="100%"
                        required
                      />
                    </FormControl>
                    <FormControl>
                      <FormLabel htmlFor="titular" fontSize="0.7em" color="#939598" fontWeight="bold">
                        Tipo conta bancária*
                      </FormLabel>

                      <SelectField
                        {...defaultArgs}
                        {...defaultArgsSelect}
                        name="tipo_conta"
                        placeholder="Tipo da conta"
                        options={bankAccountTypes}
                        fontSize="0.7em"
                        backgroundColor="#EFEFEF"
                        w="100%"
                        required
                      />
                    </FormControl>

                    <TextField
                      label="Titular da conta*"
                      name="titular"
                      textAlign="left"
                      fontSize="0.7em"
                      color="#939598"
                      backgroundColor="#EFEFEF"
                      maskChar={null}
                      type="text"
                      h="3.8em"
                      fontWeight="bold"
                      borderRadius="-0.5em"
                      maxLength={30}
                      required
                    />
                    <HStack flexDirection={'row'}>
                      <TextField
                        name="cnpj_conta"
                        label="CPF/CNPJ*"
                        textAlign="left"
                        fontSize="0.7em"
                        color="#939598"
                        backgroundColor="#EFEFEF"
                        maskChar={null}
                        type="tel"
                        h="3.8em"
                        fontWeight="bold"
                        borderRadius="-0.5em"
                        maxLength={25}
                        {...(documento_validacao ? {} : { value: applyMask(documento) })}
                        onChange={(e) => {
                          const unmaskedValue = e.target.value.replace(/\s/g, '');
                          setValue(unmaskedValue);
                          if (unmaskedValue.length > 6) {
                            setValue(unmaskedValue);
                            setFieldValue('cnpj_conta', unmaskedValue);
                          } else {
                            setFieldValue('cnpj_conta', '');
                          }
                        }}
                        required
                      />


                      <HStack w="70%" pt="1em">
                        <Switch
                          name="copiarwpp"
                          color="gray"
                          size="lg"
                          mt="0.4em"
                          lineHeight="-1em"
                          fontSize="0.7em"
                          onChange={(e) => {
                            mesmoDaClinica(e.target.checked, setFieldValue)
                          }}
                        ></Switch>
                        <Text color="gray" fontSize="0.8em" align="left">
                          {' '}
                          Mesmo CNPJ da clínica!
                        </Text>
                      </HStack>


                    </HStack>

                    <TextField
                      name="agencia"
                      label="Agência (sem dígito)*"
                      textAlign="left"
                      fontSize="0.7em"
                      color="#939598"
                      backgroundColor="#EFEFEF"
                      maskChar={null}
                      type="tel"
                      h="3.8em"
                      fontWeight="bold"
                      borderRadius="-0.5em"
                      maxLength={4}
                      required
                    />


                    <HStack flexDirection={'row'} w="100%">
                      <TextField
                        name="numero_conta"
                        label="N° da conta*"
                        textAlign="left"
                        fontSize="0.7em"
                        color="#939598"
                        backgroundColor="#EFEFEF"
                        maskChar={null}
                        type="tel"
                        h="3.8em"
                        fontWeight="bold"
                        borderRadius="-0.5em"
                        w="100%"
                        maxLength={13}
                        required
                      />

                      <TextField
                        name="digito_validador"
                        label="Dígito validador da conta*"
                        textAlign="left"
                        fontSize="0.7em"
                        color="#939598"
                        backgroundColor="#EFEFEF"
                        maskChar={null}
                        type="tel"
                        h="3.8em"
                        fontWeight="bold"
                        borderRadius="-0.5em"
                        w="100%"
                        maxLength={2}
                      />
                    </HStack>

                    <ButtonGroup flexDirection={'column'} w="70%" pt="10%">
                      <Button backgroundColor="#95F3EE" color="#5AB2AD" h="3em" type="submit" borderRadius="2em" isLoading={loading}>
                        Seguir
                      </Button>
                    </ButtonGroup>
                  </VStack>
                </VStack>
              );
            }}
          </Formik >
        </VStack >
      ) : (
        <>
          <Loading />
        </>
      )
      }
    </>
  );
}

export default DadosClinicos;
