import { extendTheme } from '@chakra-ui/react';
import { Steps } from 'chakra-ui-steps';

const theme = {
  components: {
    Steps,
  },
  breakpoints: {
    sm: '280px',
    md: '376px',
    lg: '819px',
    xl: '1200px',
  },
  fonts: {
    heading: `'Montserrat', Readex Pro`,
    body: `'Montserrat', Readex Pro`,
  },
};

export default extendTheme(theme);
