import { Alert, AlertDescription, AlertIcon, AlertTitle, Button, VStack, Text } from '@chakra-ui/react';
import { React, useContext } from 'react';
import { useNavigate } from 'react-router-dom';

import { AccountContext } from './components/AccountContext';

function Erro(error, resetErrorBoundary) {
  const navigate = useNavigate();
  const { user, page, setPage } = useContext(AccountContext);
  if (page === 'Titulo da Pagina' || page === '' || !page) {
    setPage('');
  }

  return (
    <>
      {user.loggedIn ? <></> : <></>}
      <VStack w={{ base: '90%', md: '500px' }} m="auto" justify="top" h="100vh" spacing="1rem" marginTop="2">
        <Alert status="error" variant="subtle" flexDirection="column" alignItems="center" justifyContent="center" textAlign="center">
          <AlertIcon boxSize="40px" mr={0} />
          <AlertTitle mt={4} mb={1} fontSize="lg">
            Algo deu errado!
          </AlertTitle>
          <AlertDescription maxWidth="sm">
            <p>
              Estamos trabalhando para solucionar este imprevisto o mais rápido possível, tente novamente e caso persista entre em contato com o
              suporte
            </p>
            <Button
              onClick={() => {
                resetErrorBoundary();
                navigate('/dashboard');
              }}
            >
              OK
            </Button>
          </AlertDescription>
        </Alert>
        <Text fontSize="xs">{error.name + ': ' + error.message}</Text>
      </VStack>
    </>
  );
}

function ErrorFallback({ error, resetErrorBoundary }) {
  return Erro(error, resetErrorBoundary);
}

export default ErrorFallback;
