import {
  Center,
  Heading,
  chakra,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalOverlay,
  Stack,
  Button,
  ModalFooter,
  Text,
  VStack,
  ButtonGroup,
  useToast,
} from '@chakra-ui/react';
import React, { useContext, useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { AccountContext } from '../../../AccountContext';

function Atestado(props) {
  const disclosure = props.disclosure;
  const { dados_clinicos, paciente } = props;
  const { setUser, user } = useContext(AccountContext);
  const navigate = useNavigate();
  const toast = useToast();
  const dataAtual = new Date();
  const dia = String(dataAtual.getDate()).padStart(2, '0');
  const mes = String(dataAtual.getMonth() + 1).padStart(2, '0');
  const ano = dataAtual.getFullYear();
  const horas = String(dataAtual.getHours()).padStart(2, '0');
  const minutos = String(dataAtual.getMinutes()).padStart(2, '0');
  const [atestado, setAtestado] = useState([]);
  const [texto, setTexto] = useState('');

  const formatarCPF = (cpf) => {
    return cpf.replace(/(\d{3})(\d{3})(\d{3})(\d{2})/, '$1.$2.$3-$4');
  };

  const converterParaExtenso = (numero) => {
    const unidades = ['', 'um', 'dois', 'três', 'quatro', 'cinco', 'seis', 'sete', 'oito', 'nove'];
    const especiais = ['dez', 'onze', 'doze', 'treze', 'quatorze', 'quinze', 'dezesseis', 'dezessete', 'dezoito', 'dezenove'];
    const dezenas = ['vinte', 'trinta', 'quarenta', 'cinquenta', 'sessenta', 'setenta', 'oitenta', 'noventa'];
    const centenas = ['cento', 'duzentos', 'trezentos', 'quatrocentos', 'quinhentos', 'seiscentos', 'setecentos', 'oitocentos', 'novecentos'];

    if (numero < 10) {
      return unidades[numero];
    } else if (numero < 20) {
      return especiais[numero - 10];
    } else if (numero < 100) {
      const dezena = Math.floor(numero / 10);
      const resto = numero % 10;
      if (resto === 0) {
        return dezenas[dezena - 2];
      } else {
        return `${dezenas[dezena - 2]} e ${unidades[resto]}`;
      }
    } else if (numero < 1000) {
      const centena = Math.floor(numero / 100);
      const resto = numero % 100;
      if (resto === 0) {
        return centenas[centena - 1];
      } else {
        return `${centenas[centena - 1]} e ${converterParaExtenso(resto)}`;
      }
    } else if (numero < 10000) {
      const milhar = Math.floor(numero / 1000);
      const resto = numero % 1000;
      if (resto === 0) {
        return `${unidades[milhar]} mil`;
      } else {
        return `${unidades[milhar]} mil e ${converterParaExtenso(resto)}`;
      }
    }
  };

  useEffect(() => {
    if (atestado && atestado.length > 0) {
      setUser((prevState) => ({
        ...prevState,
        cart: {
          ...user.cart,
          atestado:
            prevState.cart && prevState.cart.atestado && prevState.cart.atestado.length ? [...prevState.cart.atestado, ...atestado] : [...atestado],
        },
      }));
      setAtestado([]);
    }
  }, [atestado]);

  const diasPorExtenso = converterParaExtenso(dados_clinicos?.qntde_dias);
  const quadro_clinico = dados_clinicos?.quadro_clinico;
  const medicamentos = dados_clinicos?.medicamentos;
  const qntde = dados_clinicos?.qntde_dias;
  function callToast(title, description, status) {
    toast({
      title: title,
      description: description,
      status: status,
      duration: 4000,
      isClosable: true,
      position: 'bottom',
      colorScheme: 'red',
      containerStyle: {
        paddingTop: '50px',
      },
    });
  }
  return (
    <>
      <Modal isOpen={disclosure.isOpen} isCentered onClose={disclosure.onClose}>
        <ModalOverlay />
        <ModalContent maxW="85%" borderRadius="2EM">
          <ModalCloseButton />
          <ModalBody>
            <Center>
              <Heading textAlign="center" color="red" mt="5" size="md">
                {props?.dados_clinicos?.tipoAtestado?.nome ? props?.dados_clinicos?.tipoAtestado?.nome : 'Atestado'}
              </Heading>
            </Center>
            {/* {atestado?.map((item, index) => { */}
            {/* console.log(atestado)
                            return (
                                <> */}
            <chakra.h4 mt="0.2em" color="#808080" fontSize="1.2em" textAlign={'justify'}>
              Eu <b>{user?.nome}</b>, atesto para os devidos fins, a pedido que o(a) Sr(a). <b>{paciente?.nome}</b>, inscrito(a) no CPF sob o N°{' '}
              <b>{formatarCPF(paciente?.cpf)}</b>, paciente sob meus cuidados, foi atendido(a) no dia <b>{`${dia}/${mes}/${ano}`}</b> às{' '}
              <b>{`${horas}:${minutos}`}</b>, apresentando quadro de <b>{quadro_clinico}</b> que será tratado com <b>{medicamentos}</b> e necessitando
              de{' '}
              <b>
                {qntde} ({diasPorExtenso}){' '}
              </b>{' '}
              dias de repouso.
            </chakra.h4>
            <VStack></VStack>
            {/* </>
                            ) */}
            {/* })} */}
            <VStack>
              <ButtonGroup flexDirection={'row'} pt="15%">
                <Button
                  backgroundColor=" red "
                  type="submit"
                  onClick={() => {
                    const atestado_tipo_id = props?.dados_clinicos?.tipoAtestado?.tipo_atestado_id;

                    setAtestado((prevState) => [
                      ...prevState,
                      {
                        atestado_id: atestado_tipo_id,
                        texto: `Eu ${user?.nome}, atesto para os devidos fins, a pedido que o(a) Sr(a). ${
                          paciente?.nome
                        }, inscrito(a) no CPF sob o N° ${formatarCPF(
                          paciente?.cpf
                        )}, paciente sob meus cuidados, foi atendido(a) no dia ${`${dia}/${mes}/${ano}`} às ${`${horas}:${minutos}`}, apresentando quadro de ${quadro_clinico} que será tratado com ${medicamentos} e necessitando de ${qntde} (${diasPorExtenso}) dias de repouso.`,
                      },
                    ]);

                    callToast('Sucesso', 'Atestado adicionado com sucesso!', 'success');
                    disclosure.onClose();
                  }}
                  w="80%"
                  color="#ffff"
                  h="3em"
                  borderRadius="0.8em"
                >
                  Concluir
                </Button>

                <Button
                  backgroundColor="#4D9140"
                  type="submit"
                  onClick={() => {
                    disclosure.onClose();
                  }}
                  w="75%"
                  color="#ffff"
                  h="3em"
                  borderRadius="0.8em"
                >
                  Editar
                </Button>
              </ButtonGroup>
            </VStack>
          </ModalBody>
        </ModalContent>
      </Modal>
    </>
  );
}

export default Atestado;
