import {
  Button,
  useToast,
  ButtonGroup,
  Heading,
  Text,
  VStack,
  chakra,
  Img,
  Input,
  Center,
  Grid,
  GridItem,
  Flex,
  HStack,
  Stack,
} from '@chakra-ui/react';
import Voltar from '../../assets/images/voltar.svg';
import { React, useContext, useEffect, useState } from 'react';
import { AccountContext } from '../AccountContext';
import { useLocation, useNavigate } from 'react-router-dom';
import { Form, Formik, useFormikContext } from 'formik';
import TextField from '../Utils/Inputs/TextField';
import Medicos from '../../assets/images/medicos.png';
import NoUserHeader from '../Utils/Headers/NoUserHeader';
import * as Yup from 'yup';
import { parse } from 'date-fns';
function DadosClinicos() {
  const { setUser, setPage } = useContext(AccountContext);
  const [error, setError] = useState(null);
  const navigate = useNavigate();
  const toast = useToast();
  const { state } = useLocation();
  const onlyNumbers = (str) => str?.replace(/[^0-9]/g, '');
  const [location, setLocation] = useState();
  const [blockButton, setButtonBlock] = useState(true);
  const [loading, setIsLoading] = useState(false);
  setPage('Cadastro');
  const [localidades, setLocalidades] = useState({ municipios: [], estados: [] });
  const [cep_clinica, setCep] = useState();
  function showToast() {
    toast({
      title: '',
      position: 'top',
      description: 'Sistema em Manutenção',
      status: 'error',
      duration: 2000,
      isClosable: true,
      containerStyle: {
        paddingTop: '50px',
      },
    });
  }

  function callToast(title, description, status) {
    toast({
      title: title,
      description: description,
      status: status,
      duration: 4000,
      isClosable: true,
      position: 'top',
      colorScheme: 'red',
      containerStyle: {
        paddingTop: '50px',
      },
    })
  }
  let toastDisplayed = false;

  async function buscarCep(cep_clinica, setFieldValue) {

    const validate = onlyNumbers(cep_clinica);


    try {
      if (validate.length === 8) {
        const response = await fetch(`https://viacep.com.br/ws/${validate}/json/`);
        if (!response.ok) {
          setButtonBlock(true);
          console.error('Erro ao consultar o ViaCEP');
          return;
        }

        const result = await response.json();

        if (result.erro) {
          setIsLoading(false);
          setButtonBlock(true);
          callToast('Importante', 'CEP não encontrado.', 'error');
          setFieldValue('cep_clinica', '');
          return;
        }

        setButtonBlock(false);
        setIsLoading(false);
        setLocation(result);
        setCep(validate)
        setFieldValue('rua_clinica', result.logradouro);
        setFieldValue('bairro_clinica', result.bairro);
        setFieldValue('uf_clinica', result.uf);
        setFieldValue('cidade_clinica', result.localidade);
        setFieldValue('complemento', result.complemento);
        setFieldValue('municipio_id', result.ibge);
      }

    } catch (error) {
      console.log(error);
      setButtonBlock(true);
      setIsLoading(false);
      callToast('Não foi possível consultar seu CEP, verifique o CEP informado.');
    }
  }

  async function validaUf(uf_clinica) {
    setIsLoading(true)
    try {
      const response = await fetch(`${process.env.REACT_APP_BASE_URL_API_SERVER}/clients/valida_uf/${uf_clinica}`, {
        method: 'POST',
        credentials: 'include',
        headers: {
          'Content-Type': 'application/json',
        },
      });

      const resposta = await response.json();

      if (resposta.message === 'existe') {
        return true;

      }
      setIsLoading(false)
      return false;
    } catch (error) {
      console.error('Error during validaUf:', error);
      return false;
    }
  }


  const normalizeCEP = (cep) => cep.replace(/\D/g, '');
  return (
    <>
      <NoUserHeader />
      <VStack>
        {' '}
        <Formik
          initialValues={{
            cep_clinica: '',
            rua_clinica: '',
            numero_clinica: '',
            bairro_clinica: '',
            uf_clinica: '',
            complemento_clinica: '',
            cidade_clinica: '',
          }}
          validateOnChange={false}
          validateOnBlur={false}
          validationSchema={Yup.object({
            cep_clinica: Yup.string().required('CEP é obrigatório!').test('validacao-cep', 'CEP inválido', (value) => {
              const cep = normalizeCEP(value);
              const cepRegex = /^[0-9]{8}$/;
              if (!cepRegex.test(cep)) {
                return false;
              }
              return true;
            }),
            rua_clinica: Yup.string().required('Rua é obrigatório!').matches(/^[A-Za-zÀ-ÖØ-öø-ÿ\s0-9']+$/, 'Rua não deve conter caracteres especiais, exceto números'),
            bairro_clinica: Yup.string().required('Bairro é obrigatório!').matches(/^[A-Za-zÀ-ÖØ-öø-ÿ\s0-9']+$/, 'Bairro não deve conter caracteres especiais, exceto números'),
            uf_clinica: Yup.string()
              .required('Campo obrigatório')
              .matches(/^[^\d]+$/, 'Escreva a UF sem acentos, números ou caracteres especiais')
              .max(2, 'A UF não pode conter mais de 2 caracteres')
              .test('validate-uf', 'UF inválida', async function (value) {
                if (value) {
                  const isValid = await validaUf(value)
                  return isValid;
                }
                return true;
              }),
            cidade_clinica: Yup.string()
              .matches(/^[a-zA-ZÀ-ÖØ-öø-ÿ\s']*$/, {
                message: 'Somente letras, espaços e acentos são permitidos na cidade',
              }).required('Campo obrigatório'),
            numero_clinica: Yup.string().required('Número é obrigatório!').matches(/^[A-Za-zÀ-ÖØ-öø-ÿ\s0-9']+$/, 'Número não deve conter caracteres especiais, exceto números'),
          })}
          onSubmit={(values, actions) => {
            const vals = { ...values };
            navigate('/CPF', {
              state: {
                ...state,
                cep_clinica: cep_clinica,
                rua_clinica: location.logradouro ? location.logradouro : vals.rua_clinica,
                numero_clinica: onlyNumbers(vals.numero_clinica),
                bairro_clinica: location.bairro ? location.bairro : vals.bairro_clinica,
                uf_clinica: location.uf ? location.uf : vals.uf_clinica,
                cidade_clinica: location.localidade ? location.localidade : vals.cidade_clinica,
                complemento_clinica: location.complemento ? location.complemento : vals.complemento_clinica,
                municipio_id: location.ibge ? location.ibge : vals.municipio_id,
              },
            });
          }}
        >
          {(props) => {
            const { setFieldValue } = props;
            return (
              <VStack as={Form} w="100%">
                <VStack w="90%" textAlign={'center'}>
                  <Text fontSize="1em" lineHeight="1em" fontWeight={'bold'} textAlign={'left'} color="#5AB2AD" noOfLines={3} maxWidth="90%">
                    Cadastre o endereço completo da clínica.
                  </Text>
                  <TextField
                    label="CEP*"
                    name="cep_clinica"
                    mask="99999-999"
                    placeholder="Campo obrigatório"
                    textAlign="left"
                    fontSize="0.7em"
                    color="#939598"
                    backgroundColor="#EFEFEF"
                    maskChar={null}
                    type="tel"
                    h="3.8em"
                    fontWeight="bold"
                    borderRadius="-0.5em"
                    w="100%"
                    onKeyUp={(e) => {
                      buscarCep(e.target.value, setFieldValue);
                    }}
                    maxLength={9}
                  />

                  <HStack w="100%">
                    <TextField
                      name="rua_clinica"
                      label="Rua*"
                      placeholder=""
                      textAlign="left"
                      fontSize="0.7em"
                      color="#939598"
                      backgroundColor="#EFEFEF"
                      maskChar={null}
                      type="text"
                      h="3.8em"
                      fontWeight="bold"
                      borderRadius="-0.5em"
                      maxLength={100}
                    />
                  </HStack>

                  <HStack w="100%">
                    <TextField
                      name="numero_clinica"
                      label="N°"
                      placeholder=""
                      textAlign="left"
                      fontSize="0.7em"
                      color="#939598"
                      backgroundColor="#EFEFEF"
                      maskChar={null}
                      type="tel"
                      h="3.8em"
                      fontWeight="bold"
                      borderRadius="-0.5em"
                      maxLength={10}
                    />

                    <TextField
                      name="bairro_clinica"
                      label="Bairro*"
                      textAlign="left"
                      fontSize="0.7em"
                      placeholder=""
                      color="#939598"
                      backgroundColor="#EFEFEF"
                      maskChar={null}
                      type="text"
                      h="3.8em"
                      fontWeight="bold"
                      borderRadius="-0.5em"
                      maxLength={40}
                    />
                  </HStack>

                  <HStack w="100%">
                    <TextField
                      name="uf_clinica"
                      label="UF*"
                      textAlign="left"
                      fontSize="0.7em"
                      placeholder=""
                      color="#939598"
                      backgroundColor="#EFEFEF"
                      maskChar={null}
                      type="text"
                      h="3.8em"
                      maxLength={2}
                      fontWeight="bold"
                      borderRadius="-0.5em"
                    />

                    <TextField
                      name="cidade_clinica"
                      label="Cidade*"
                      textAlign="left"
                      fontSize="0.7em"
                      color="#939598"
                      backgroundColor="#EFEFEF"
                      placeholder=""
                      maskChar={null}
                      type="text"
                      h="3.8em"
                      fontWeight="bold"
                      borderRadius="-0.5em"
                      maxLength={40}
                    />
                  </HStack>

                  <HStack w="100%">
                    <TextField
                      name="complemento_clinica"
                      label="Complemento"
                      placeholder=""
                      textAlign="left"
                      fontSize="0.7em"
                      color="#939598"
                      backgroundColor="#EFEFEF"
                      maskChar={null}
                      type="text"
                      h="3.8em"
                      fontWeight="bold"
                      borderRadius="-0.5em"
                    />
                  </HStack>

                  <ButtonGroup flexDirection={'column'} w="70%" pt={{ md: '10%', sm: '5%' }}>
                    <Button backgroundColor={"#95F3EE"} color={"#5AB2AD"} h="3em" type="submit" borderRadius="2em" isDisabled={blockButton}>
                      {blockButton ? 'Preencha todos os campos' : 'Seguir'}
                    </Button>
                  </ButtonGroup>
                </VStack>
              </VStack>
            );
          }}
        </Formik>
      </VStack>
    </>
  );
}
// }

export default DadosClinicos;
