import { Button, useToast, ButtonGroup, Heading, Text, VStack, chakra, Img, Center } from '@chakra-ui/react';
import Logo from '../../assets/images/deo-med.svg';
import Recepcao from '../../assets/images/medica-recepcao.svg';
import Balcao from '../../assets/images/balcao.svg';

import { React, useContext, useState } from 'react';

import { AccountContext } from '../AccountContext';
import { useNavigate } from 'react-router-dom';

const Opening = () => {
  const { setUser } = useContext(AccountContext);
  const [error, setError] = useState(null);
  const navigate = useNavigate();
  const toast = useToast();

  function showToast() {
    toast({
      title: '',
      position: 'top',
      description: 'Sistema em Manutenção',
      status: 'error',
      duration: 2000,
      isClosable: true,
      containerStyle: {
        paddingTop: '50px',
      },
    });
  }

  return (
    <chakra.div backgroundColor="#E0F0EF" h="100vh">
      <VStack w="100%" m="auto" justify="center" spacing={{ sm: '1rem', md: '3rem' }}>
        <Heading mt={{ sm: '30%', md: '50%' }}>
          <img src={Logo} alt="logo deovita" />
        </Heading>
        <Text as="p" color="#FFFFFF">
          {error}
        </Text>

        <VStack w="90%">
          <Text fontSize="2em" lineHeight="1em" fontWeight={'bold'} textAlign={'center'} color="#95F3EE" noOfLines={3} maxWidth="70%">
            Cashback para a sua clínica!
          </Text>

          <VStack spacing={-0.8}>
            <Text fontSize="0.7em" textAlign={'center'} color="#FFFFFF" noOfLines={1} fontWeight="bold" maxWidth="60%">
              Além dos exames realizados,
            </Text>
            <Text textAlign={'center'} fontSize="0.7em" color="#FFFFFF" fontWeight="bold" maxWidth="60%">
              você também ganha cashback das assinaturas que vierem a partir da solicitação de sua clínica
            </Text>
          </VStack>

          <ButtonGroup sx={{ width: '70%' }}>
            <Button
              aria-label="ok"
              colorScheme="teal"
              type="submit"
              mt="0.5em"
              w={{
                base: '100%',
              }}
              background="white"
              color="#5AB2AD"
              height="3em"
              borderRadius="0.6em"
              onClick={() => navigate('/login')}
            >
              Login
            </Button>
          </ButtonGroup>

          <ButtonGroup sx={{ width: '70%' }}>
            <Button
              aria-label="ok"
              colorScheme="teal"
              type="submit"
              w={{
                base: '100%',
              }}
              background="#95F3EE"
              color="#5AB2AD"
              height="3em"
              borderRadius="0.6em"
              onClick={() => navigate('/CNPJ')}
            >
              Cadastre-se
            </Button>
          </ButtonGroup>
        </VStack>
      </VStack>
    </chakra.div>
  );
};

export default Opening;
