import React, { useContext, useEffect, useState } from 'react'
import NoUserHeader from '../../Utils/Headers/NoUserHeader'
import { useNavigate } from 'react-router-dom';
import { AccountContext } from '../../AccountContext';
import { Button, ButtonGroup, Divider, HStack, Table, Text, VStack } from '@chakra-ui/react';

function CadastroModelo() {
    const { user, setPage } = useContext(AccountContext);
    const navigate = useNavigate();
    setPage('Cadastro Modelo Atestado');
    const [modelos, setModelos] = useState();

    function removerTagsHTML(html) {
        return html.replace(/<[^>]*>/g, '');
    }


    const getModelos = () => {
        fetch(`${process.env.REACT_APP_BASE_URL_API_SERVER}/clients/getModelosAtestados/${user.operador_id}`, {
            method: 'POST',
            credentials: 'include',
            headers: {
                'Content-Type': 'application/json',
            },
        }).catch((err) => {
            console.log(err);
            return;
        }).then(async (response) => {
            if (!response || !response.ok || response.status >= 400) {
                return;
            }
            return response.json();
        }).then((result) => {
            setModelos(result)
        });
    };

    useEffect(() => {
        getModelos();
    }, []);

    const useModelos = modelos?.modelos?.map((item, index) => {
        const texto = removerTagsHTML(item.texto)
        return (
            <>
                <tr>
                    <td><b>Nome: </b>{item.nome}</td>
                </tr>
                <tr>
                    <td>
                        <b>Descrição: </b> {texto}
                    </td>
                    <td borderBottom="1px solid black">

                    </td>
                </tr>
                <Divider w={'105%'} colorScheme="blackAlpha" />
            </>
        )

    })

    return (
        <>
            <NoUserHeader />
            <Table w={'90%'} ml="2em">
                {useModelos}
            </Table>
            <ButtonGroup flexDirection={'column'} w={{ md: '20em', sm: '15em' }} ml={{ md: '2.5em', sm: '4em' }} mt={{ md: '15em', sm: '1em' }}>
                <Button backgroundColor="#95F3EE" color="#5AB2AD" h="3em" type="submit" borderRadius="2em" onClick={() => navigate('/medicos/cadastro-lista-modelo')}>
                    Cadastrar novo modelo
                </Button>
            </ButtonGroup>

        </>
    )
}

export default CadastroModelo