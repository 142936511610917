import { Img, VStack } from '@chakra-ui/react';
import React from 'react';
import Spin from '../../assets/images/spin.svg';
import './css/loading.css';

function Loading() {
  return (
    <>
      <VStack w="90%" m="50% auto" h={'10vh'} justify="center">
        <Img size="sm" margin="auto" src={Spin} className="rotate" />
      </VStack>
    </>
  );
}

export default Loading;
