import {
  Button,
  ButtonGroup,
  chakra,
  Center,
  Flex,
  Heading,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalOverlay,
  useToast,
  VStack,
  Stack,
  ModalFooter,
  Switch,
  useDisclosure,
  Text,
  HStack,
} from '@chakra-ui/react';
import { Field, Form, Formik } from 'formik';
import React, { useEffect, useRef } from 'react';
import { useState } from 'react';
import { useContext } from 'react';
import DoctorHeader from '../../../Utils/Headers/DoctorHeader';
import { AccountContext } from '../../../AccountContext';
import TextField from '../../../Utils/Inputs/TextField';
import * as Yup from 'yup';
import { parse } from 'date-fns';
import Atestado from '../../../Utils/Modal/Medicos/Atestado';
import { useLocation } from 'react-router-dom';
import SelectField from '../../../../components/SelectField'

function Main() {
  const { user, setUser, setPage } = useContext(AccountContext);
  setPage('Atestado');
  const textAreaRef = useRef(null);
  const [error, setError] = useState('');
  const [atestado, setAtestado] = useState();
  const [modelos, setModelosAtestados] = useState([]);
  const [formItens, setFormItens] = useState([]);
  const disclosure = useDisclosure();
  const toast = useToast();
  const { state } = useLocation();
  const [tiposAtestados, setTiposAtestados] = useState([]);
  const [ultimaSelecao, setUltimaSelecao] = useState([]);
  const handleInput = () => {
    const textArea = textAreaRef.current;
    textArea.style.height = 'auto';
    textArea.style.height = `${textArea.scrollHeight}px`;
  };

  const searchTiposAtestados = () => {
    fetch(`${process.env.REACT_APP_BASE_URL_API_SERVER}/clients/searchTiposAtestados/`, {
      method: 'POST',
      credentials: 'include',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({}),
    })
      .catch((err) => {
        return err;
      })
      .then((res) => {
        if (!res || !res.ok || res.status >= 400) {
        }
        return res.json();
      })
      .then(async(response) => {
        if (response.modelos.length > 0) {
          setTiposAtestados(response.modelos);
        } else {
          setTiposAtestados([]);
        }
      });
  };

  useEffect(() => {
    searchTiposAtestados();
  }, []);

  function calcularIdade(dataNascimento) {
    const hoje = new Date();
    const nascimento = new Date(dataNascimento);

    let idade = hoje.getFullYear() - nascimento.getFullYear();
    const mesAtual = hoje.getMonth();
    const diaAtual = hoje.getDate();
    const mesNascimento = nascimento.getMonth();
    const diaNascimento = nascimento.getDate();

    if (mesAtual < mesNascimento || (mesAtual === mesNascimento && diaAtual < diaNascimento)) {
      idade--;
    }

    return idade;
  }
  const idade = calcularIdade(state.paciente.nascimento);

  useEffect(() => {
    if (formItens?.length > 0) {
      formItens?.resetForm();
    }
  }, [user.cart]);

  const [qntdeDias, setQntdeDias] = useState('');

  const handleInputChange = (event) => {
    let inputValue = event.target.value;
    if (inputValue.length > 4) {
      inputValue = inputValue.slice(0, 4);
    }
    setQntdeDias(inputValue);
  };

  const SelectClient = () => {
    if (!tiposAtestados) {
      return;
    }
    const arrayTiposAtestados = tiposAtestados?.map((atestado) => atestado.nome);
    console.log(arrayTiposAtestados)
    return (
      <SelectField
        name="tiposAtestados"
        placeholder="Tipo Atestado"
        w="100%"
        variant="outline"
        textAlign="left"
        backgroundColor="#EFEFEF"
        fontWeight="normal"
        onChange={(e) => {}}
        options={arrayTiposAtestados}
      />
    );
  };

  return (
    <>
      <DoctorHeader />
      <Atestado disclosure={disclosure} dados_clinicos={atestado} paciente={state.paciente} />
      <VStack w="100%" mt="5%">
        <Formik
          initialValues={{
            quadro_clinico: '',
            medicamentos: '',
            qntde_dias: '',
          }}
          validationSchema={Yup.object({
            tiposAtestados: Yup.number().required('É necessário escolher uma opção'),
            quadro_clinico: Yup.string()
              .trim()
              .required('Por favor, forneça o quadro clínico.')
              .min(5, 'O quadro clínico deve ter no mínimo 5 caracteres.')
              .max(200, 'O quadro clínico não pode exceder 200 caracteres.'),
            medicamentos: Yup.string()
              .trim()
              .required('Por favor, forneça os medicamentos.')
              .min(5, 'Os medicamentos devem ter no mínimo 5 caracteres.')
              .max(200, 'Os medicamentos não podem exceder 200 caracteres.'),
            qntde_dias: Yup.number()
              .required('Por favor, forneça a quantidade de dias de dispensa.')
              .positive('A quantidade de dias de dispensa deve ser um número positivo.')
              .integer('A quantidade de dias de dispensa deve ser um número inteiro.')
              .test('len', 'A quantidade de dias de dispensa deve ter no máximo 4 caracteres.', (val) => val.toString().length <= 5),
          })}
          onSubmit={(values, actions) => {
            const vals = {
              quadro_clinico: values.quadro_clinico,
              medicamentos: values.medicamentos,
              qntde_dias: qntdeDias,
              tipoAtestado: tiposAtestados[values.tiposAtestados]
            };

            setAtestado(vals);
            setFormItens(actions);
            disclosure.onOpen();

            // toast({
            //     title: 'Atestado Adicionado',
            //     position: 'bottom',
            //     description: '',
            //     status: 'success',
            //     duration: 2000,
            //     isClosable: true,
            //     containerStyle: {
            //         paddingBottom: '3em',
            //     },
            // });
            // actions.resetForm();
          }}
        >
          <VStack as={Form} w="90%" alignItems="center">
            <VStack w="100%" bg={'#D7E7E6'} borderRadius={'1em'} mb={'2em'}>
              <Text color="#656565" textAlign="left" w="80%" fontSize="0.8em" mb="0.5em">
                Paciente:
              </Text>
              <Text color="#656565" textAlign="left" fontWeight="bold" w="80%" fontSize="0.8em" mb="0.5em">
                {state.paciente.nome}
              </Text>

              <Text color="#656565" textAlign="left" w="80%" fontSize="0.8em" mb="0.5em">
                Idade:
              </Text>

              <Text color="#656565" textAlign="left" fontWeight="bold" w="80%" fontSize="0.8em" mb="0.5em">
                {idade}
              </Text>

              <Text color="#656565" textAlign="left" w="80%" fontSize="0.8em" mb="0.5em">
                Sexo:
              </Text>

              <Text color="#656565" textAlign="left" fontWeight="bold" w="80%" fontSize="0.8em" mb="0.5em">
                {state.paciente.sexo === 'f' || state.paciente.sexo === 'F'
                  ? 'Feminino'
                  : state.paciente.sexo === 'm' || state.paciente.sexo === 'M'
                  ? 'Masculino'
                  : 'Não informado'}
              </Text>
            </VStack>
            <SelectClient />
            <TextField
              label="Quadro clínico*"
              name="quadro_clinico"
              placeholder=""
              textAlign="left"
              fontSize="0.7em"
              color="#939598"
              backgroundColor="#EFEFEF"
              maskChar={null}
              type="text"
              h="3.8em"
              fontWeight="bold"
              borderRadius="-0.5em"
              autoComplete="off"
              maxLength={50}
            />

            {/* <TextField
                            label="Cuidados clínico*"
                            name="cuidados_clinico"
                            placeholder=""
                            textAlign="left"
                            fontSize="0.7em"
                            color="#939598"
                            backgroundColor="#EFEFEF"
                            maskChar={null}
                            type="text"
                            h="3.8em"
                            fontWeight="bold"
                            borderRadius="-0.5em"
                            autoComplete="off"
                            maxLength={50}
                        /> */}

            <TextField
              label="Médicamentos*"
              name="medicamentos"
              placeholder=""
              textAlign="left"
              fontSize="0.7em"
              color="#939598"
              backgroundColor="#EFEFEF"
              maskChar={null}
              type="text"
              h="3.8em"
              fontWeight="bold"
              borderRadius="-0.5em"
              autoComplete="off"
              maxLength={50}
            />

            <TextField
              label="Quantidade dias dispensa*"
              name="qntde_dias"
              placeholder=""
              textAlign="left"
              fontSize="0.7em"
              color="#939598"
              backgroundColor="#EFEFEF"
              maskChar={null}
              type="number"
              h="3.8em"
              fontWeight="bold"
              borderRadius="-0.5em"
              autoComplete="off"
              maxLength={4}
              value={qntdeDias}
              onInput={handleInputChange}
            />
            <ButtonGroup pt="5%" w="100%" pb="5%">
              <Flex flexDirection={'column'} w="100%" align="center">
                <Button colorScheme="teal" w="60%" h="3em" mb="5%" type="submit" borderRadius="2em">
                  Visualizar atestado
                </Button>
              </Flex>
            </ButtonGroup>
          </VStack>
        </Formik>
      </VStack>
    </>
  );
}

export default Main;
