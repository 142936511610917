import { HStack, Text, VStack } from '@chakra-ui/react';
import React, { useContext } from 'react';
import { IoArrowBackCircleOutline } from 'react-icons/io5';
import { useNavigate } from 'react-router-dom';
import { AccountContext } from '../../AccountContext';

function NoUserHeader() {
  const navigate = useNavigate();
  const { page } = useContext(AccountContext);
  return (
    <>
      <VStack shadow="md" h={{ md: '8em', sm: '10em' }} backgroundColor="#5AB2AD" mb="5%">
        <HStack ml="-2%" pt="5em" w="85%">
          <IoArrowBackCircleOutline w="20%" size="2rem" color="#95F3EE" onClick={() => navigate(-1)} />
          <Text color="white" textAlign="center" fontWeight="bold" w="100%" fontSize="xl">
            {page}
          </Text>
        </HStack>
      </VStack>
    </>
  );
}

export default NoUserHeader;
